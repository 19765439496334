import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useMachine } from '@xstate/react';
import { inspect } from '@xstate/inspect';

import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import LeavePageDialog from 'core/components/leave-page-dialog';
import { useToast } from 'core/components/toast';
import {
  HouseholdMembers,
  PrimaryApplicantInsuranceType,
  ResidentsWithExternalPIP,
  ResidentsWithQHC,
  PrimaryHasQHC,
  SelectQHC,
  ExcludeSelectedResidents,
  ExcludeEveryone,
  WaiveWorkLossCoverage,
  ChoosePipAndAttendanceCare,
  PrimaryHasMedicare
} from '../pip-wizard-steps';
import { ModalFooter } from '../modal-footer';
import PersonalInjuryProtectionMachine from '../personal-injury-protection.machine';
import { Note } from './note';
import useStyles from './pip-wizard-modal.styles';

if (
  typeof window !== 'undefined' &&
  process.env.NODE_ENV !== 'production' &&
  process.env.PUBLIC_XSTATE_INSPECT === 'true'
) {
  inspect({
    url: 'https://statecharts.io/inspect',
    iframe: false
  });
}

const STEPS = {
  ADD_ANY_ADDITIONAL_HOUSEHOLD_MEMBERS: 'step1_addNewHouseholdMembers',
  PRIMARY_INSURANCE_TYPE: 'step2_primaryApplicantInsuranceType',
  RESIDENTS_WITH_QHC: 'step3A_indicateIfResidentsHaveQHC',
  PRIMARY_HAS_QHC: 'step3B_primaryHasPersonal',
  PRIMARY_HAS_MEDICARE: 'step3C_primaryHasMedicare',
  SELECT_QHC: 'step4_selectQHCResidents',
  RESIDENTS_WITH_EXTERNAL_PIP: 'step5_residentsWithExternalPIP',
  EXCLUDE_SELECTED_RESIDENTS: 'step6A_excludeResidents',
  EXCLUDE_EVERYONE: 'step6B_excludeEveryoneFromPIP',
  WAIVE_WORK_LOSS_COVERAGE: 'step7_waiveWorkLossCoverage',
  CHOOSE_PIP_AND_ATTENDANCE_CARE: 'step8_choosePIPCoverage'
};

const DATA_FOR_STEPS = {
  [STEPS.ADD_ANY_ADDITIONAL_HOUSEHOLD_MEMBERS]: {
    title: 'Add any additional household members',
    subtitle:
      'Household members should include any young children or non-driving relatives that live with the primary applicant.',
    Component: HouseholdMembers
  },
  [STEPS.PRIMARY_INSURANCE_TYPE]: {
    title: 'Indicate the primary applicant’s health insurance details',
    Component: PrimaryApplicantInsuranceType
  },
  [STEPS.RESIDENTS_WITH_QHC]: {
    title: 'Indicate if anyone in the household may have qualified health coverage',
    Component: ResidentsWithQHC
  },
  [STEPS.PRIMARY_HAS_QHC]: {
    title: 'Indicate if the primary applicant has qualified health coverage',
    Component: PrimaryHasQHC
  },
  [STEPS.PRIMARY_HAS_MEDICARE]: {
    title: 'Indicate if the primary applicant has qualified health coverage',
    Component: PrimaryHasMedicare
  },
  [STEPS.SELECT_QHC]: {
    title: 'Select who has qualified health coverage',
    subtitle:
      'Indicate which drivers or household members have Medicare with parts A & B, Medicaid, or personal health insurance that covers their medical expenses resulting from a motor vehicle accident with an annual deductible of $6,000 or less.',
    Component: SelectQHC,
    validate: (context) => {
      const { data } = context;
      const { drivers, nonDrivers } = data;
      const isValid = [...drivers, ...nonDrivers].some((person) => person.pipHasQHC);
      return {
        isValid,
        message:
          'To proceed, please select at least one person in the household who has one of the coverages mentioned in the previous step. If none of the options apply, you can go back and revise your previous answer.'
      };
    }
  },
  [STEPS.RESIDENTS_WITH_EXTERNAL_PIP]: {
    title: 'Select who has PIP coverage elsewhere',
    subtitle: 'Do any additional household members have PIP coverage from another auto insurance carrier?',
    Component: ResidentsWithExternalPIP
  },
  [STEPS.EXCLUDE_SELECTED_RESIDENTS]: {
    title: 'Indicate if these drivers or household members should be excluded from PIP medical coverage (PIP ME)',
    subtitle:
      'Excluding a person from PIP medical will exclude them from work loss coverage and attendant care coverage.',
    Component: ExcludeSelectedResidents
  },
  [STEPS.EXCLUDE_EVERYONE]: {
    title: 'Indicate if the entire household should be excluded from PIP medical coverage (PIP ME)',
    subtitle:
      'Excluding a person from PIP medical will exclude them from work loss coverage and attendant care coverage.',
    Component: ExcludeEveryone
  },
  [STEPS.WAIVE_WORK_LOSS_COVERAGE]: {
    title: 'Indicate who should be waived from Work Loss Coverage',
    subtitle:
      'Drivers and household members older than 60 are at an eligible age to waive work loss coverage. People waived from work loss coverage will still receive PIP medical coverage and attendant care coverage.',
    Component: WaiveWorkLossCoverage
  },
  [STEPS.CHOOSE_PIP_AND_ATTENDANCE_CARE]: {
    title: 'Choose PIP medical coverage and attendant care coverage',
    Component: ChoosePipAndAttendanceCare
  }
};

const PipWizardModal = ({ open, onClose }) => {
  const classes = useStyles();
  const toast = useToast();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [leaveModalWarningOpen, setLeaveModalWarningOpen] = useState(false);

  const [state, send] = useMachine(PersonalInjuryProtectionMachine, {
    devTools: process.env.NODE_ENV !== 'production',
    context: {
      history: [],
      data: {
        drivers: values.drivers.map((driver) => ({
          ...driver,
          pipOptOut: false,
          pipHasQHC: false,
          waivedPIPWL: false,
          pipEligible: true
        })),
        nonDrivers: (values.nonDrivers || []).map((resident) => ({
          ...resident,
          pipOptOut: false,
          pipHasQHC: false,
          waivedPIPWL: false,
          pipEligible: true
        })),
        pipHealthInsuranceType: values.auto.pipHealthInsuranceType,
        pipEveryoneOnSamePlan: values.auto.pipEveryoneOnSamePlan ? 'yes' : 'no',
        pipResidentsHaveQHC: 'yes',
        pipPrimaryHasQHC: 'idk',
        policyLimitPIPACR: 'NONE',
        policyLimitPIPME: 'UNLIMITED',
        excludeEveryone: 'no'
      }
    }
  });

  const { context, value } = state;
  const { title, subtitle, Component: CurrentStep, validate } = DATA_FOR_STEPS[value] || {};
  const isLastStep = value === STEPS.CHOOSE_PIP_AND_ATTENDANCE_CARE;
  const isFirstStep = value === STEPS.ADD_ANY_ADDITIONAL_HOUSEHOLD_MEMBERS;

  const updateAnswer = useCallback(
    (payload) => {
      send('UPDATE', { payload });
    },
    [send]
  );

  useEffect(() => {
    if (value === 'completed') {
      const allResidents = [...context.data.drivers, ...context.data.nonDrivers];
      setFieldValue('drivers', context.data.drivers);
      setFieldValue('nonDrivers', context.data.nonDrivers);
      setFieldValue('auto.pipHealthInsuranceType', context.data.pipHealthInsuranceType);
      setFieldValue('auto.pipAllResidents', allResidents.length);
      setFieldValue('auto.pipResidentsWithQHC', allResidents.filter((r) => r.pipHasQHC).length);
      setFieldValue('auto.pipExcludedResidents', allResidents.filter((r) => r.pipOptOut).length);
      setFieldValue('auto.pipAdditionalResidents', context.data.nonDrivers.filter((r) => !r.pipEligible).length);
      setFieldValue('auto.pipEveryoneOnSamePlan', context.data.pipEveryoneOnSamePlan === 'yes');
      setFieldValue('autoCoverage.policyLimitPIPACR', context.data.policyLimitPIPACR);
      setFieldValue('autoCoverage.policyLimitPIPME', context.data.policyLimitPIPME);
      setFieldValue('auto.hasSetPip', true);

      setFieldTouched('drivers');
      setFieldTouched('nonDrivers');
      setFieldTouched('auto.pipHealthInsuranceType');
      setFieldTouched('auto.pipAllResidents');
      setFieldTouched('auto.pipResidentsWithQHC');
      setFieldTouched('auto.pipExcludedResidents');
      setFieldTouched('auto.pipAdditionalResidents');
      setFieldTouched('auto.pipEveryoneOnSamePlan');
      setFieldTouched('autoCoverage.policyLimitPIPACR');
      setFieldTouched('autoCoverage.policyLimitPIPME');

      onClose();
    }
  }, [value]);

  const handleCloseWithoutSaving = useCallback(() => {
    setLeaveModalWarningOpen(true);
  }, [setLeaveModalWarningOpen]);

  const handlePrevious = useCallback(() => {
    send('PREVIOUS');
  }, [send]);

  const handleNext = useCallback(() => {
    const { isValid, message } = validate ? validate(context) : { isValid: true, message: '' };
    if (isValid) {
      send('NEXT');
    } else {
      updateAnswer({ isValid: false });
      toast.notify({
        type: 'error',
        message
      });
    }
  }, [send, context, validate, toast]);

  return (
    <>
      <BaseDialog
        open={open}
        onClose={handleCloseWithoutSaving}
        className={classes.modal}
        title={
          <div className={classes.modalHeader}>
            <Label type="h1">Modify PIP Coverage</Label>
            {value !== 'completed' && (
              <div className={classes.stepDescription}>
                <Label type="body1" className={classes.stepTitle}>
                  {title}
                </Label>
                {subtitle && (
                  <Label type="body2" className={classes.stepSubtitle}>
                    {subtitle}
                  </Label>
                )}
              </div>
            )}
          </div>
        }
      >
        <div className={classes.content}>
          {value !== 'completed' && <CurrentStep context={context} onUpdate={updateAnswer} />}
          <Note />
        </div>
        <ModalFooter isLastStep={isLastStep} onNext={handleNext} onPrevious={isFirstStep ? null : handlePrevious} />
      </BaseDialog>
      <LeavePageDialog
        open={leaveModalWarningOpen}
        onClose={() => setLeaveModalWarningOpen(false)}
        cb={onClose}
        title="Cancel PIP modifications"
      />
    </>
  );
};

PipWizardModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PipWizardModal;
