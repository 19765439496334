/* eslint-disable prefer-regex-literals */
export const courseDiscountTypes = {
  AVOIDANCE: 'AVOIDANCE',
  DEFENSIVE: 'DEFENSIVE',
  INEXPERIENCED: 'INEXPERIENCED',
  PREVENTION: 'PREVENTION',
  SENIOR: 'SENIOR',
  MILITARY_DEFENSIVE: 'MILITARY_DEFENSIVE',
  NATIONAL_GUARD: 'NATIONAL_GUARD'
};

export const bixStates = {
  AR: true,
  AZ: true,
  DC: true,
  FL: true,
  GA: true,
  IA: true,
  IL: true,
  IN: true,
  KY: true,
  LA: true,
  MD: true,
  MI: true,
  MO: true,
  MS: true,
  MT: true,
  ND: true,
  NE: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  TX: true,
  UT: true,
  WI: true,
  WV: true
};

export const everspanStates = {
  ID: true,
  MA: true,
  ME: true,
  NH: true,
  SD: true,
  TN: true,
  VT: true,
  WY: true
};

export const originalGSNICStates = ['AZ', 'IL', 'OH', 'MO', 'TX'];

export const insurancePaper = {
  EVERSPAN: 'EVERSPAN',
  GSNIC: 'GSNIC',
  BIX: 'BIX'
};

export const preventQuoteOnlineStates = ['FL'];

export const branchLiveStates = [
  'AL',
  'AR',
  'AZ',
  'CO',
  'DC',
  'GA',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MO',
  'MS',
  'MT',
  'ND',
  'NE',
  'NH',
  'NM',
  'OH',
  'OK',
  'OR',
  'PA',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WI',
  'WV',
  'WY'
];

export const pdInUMStates = ['DC', 'SC', 'WV'];
export const restrictedPurchaseStates = ['FL', 'GA', 'LA', 'MA', 'MD', 'MI'];
export const restrictedButAllowAgencyPartnerPagesStates = ['GA', 'MI'];
export const restrictedPurchaseOnlyForDirectStates = [
  'AZ',
  'AR',
  'DC',
  'FL',
  'GA',
  'IL',
  'IN',
  'IA',
  'KY',
  'LA',
  'MA',
  'MD',
  'MI',
  'MS',
  'MO',
  'MT',
  'NE',
  'NM',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'TX',
  'UT',
  'WV',
  'WA'
];
export const noConnectedHomeProviderSpecialOfferByState = { MD: ['SimpliSafe'] };
export const connectedHomeMonitoredNCustomExperienceStates = ['IL'];
export const noTrailerCoverageStates = ['MA', 'NJ', 'NY', 'VA'];
export const ndelFormAtApplicationStates = ['GA', 'IA', 'LA', 'MA', 'MI', 'MT', 'UT', 'WV'];
export const ndelFormAtApplicationBixStates = ['IL', 'TX'];

export const shortRateFeeStates = {
  VA: true
};

// note that in MA, we print the stamp/cert doc as the ID card, but called MRMV instead of IDCD
export const noIDCardStates = {};

export const reduFormStates = {
  NH: true
};

export const adwrFormStates = {
  AR: true,
  AZ: true,
  CO: false,
  DC: true,
  GA: true,
  IA: true,
  ID: true,
  IL: true,
  IN: true,
  KY: true,
  LA: true,
  MD: true,
  ME: true,
  MI: true,
  MO: true,
  MS: true,
  MT: true,
  ND: true,
  NE: true,
  NH: true,
  NM: false,
  OH: true,
  OK: true,
  OR: true,
  PA: false,
  SC: false,
  TN: true,
  TX: true,
  UT: true,
  VA: false,
  WI: true,
  WV: true,
  WY: false
};

export const trnoFormStates = {
  SC: true
};

export const discFormStates = {
  CO: true,
  VT: true
};

export const wgptFormStates = {
  MA: true,
  OH: true
};

export const tpanFormStates = {
  NH: true
};

export const aelcnFormStates = {
  VA: true
};

export const belcnFormStates = {
  IA: true,
  IL: true,
  KS: true,
  LA: true,
  MI: true,
  MT: true,
  NH: true,
  NM: true,
  OK: true,
  TX: true,
  VT: true
};

export const bwvcdFormStates = {
  WV: true
};

export const rtnoFormStates = {
  MI: true,
  NH: true
};

export const gbckFormStates = {
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: true,
  GA: true,
  IA: true,
  ID: false,
  IL: true,
  IN: true,
  KY: true,
  LA: true,
  MA: true,
  MD: true,
  ME: true,
  MI: true,
  MS: true,
  MT: true,
  ND: true,
  NE: true,
  NH: false,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: false,
  SC: false,
  SD: true,
  TN: true,
  TX: true,
  UT: true,
  VA: false,
  VT: true,
  WI: true,
  WV: true,
  WY: false
};

export const noNDEXStates = {
  MA: true
};

export const noANMLStates = {
  DC: true,
  IA: true,
  MA: true,
  ME: true,
  ND: true,
  NH: true,
  VA: true,
  VT: true
};

export const noROFMStates = {
  DC: true,
  MA: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  VT: true,
  WY: true
};

export const noROOFStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  WV: true,
  VT: true
};

export const noROOSStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  WV: true,
  VT: true
};

export const noROFEStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  VT: true
};

export const noROSEStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  WV: true,
  VT: true
};

export const noRCOMStates = {
  DC: true,
  ND: true,
  NH: true,
  NM: true,
  VA: true,
  WV: true,
  VT: true
};

export const noADNRStates = {
  ND: true
};

export const noADDIStates = {
  ND: true
};

export const noADTRStates = {
  ND: true
};

export const noADNOStates = {
  ND: true
};

export const noDPROStates = {
  IA: true
};

export const trccFormStateDates = {
  AL: '2022-11-22',
  AR: '2022-11-22',
  AZ: '2022-11-22',
  CO: '2023-02-15',
  GA: '2022-01-01',
  IA: '2022-11-22',
  ID: '2022-11-22',
  IL: '2022-11-22',
  IN: '2022-11-22',
  KS: '2022-11-22',
  KY: '2022-11-22',
  LA: '2022-11-22',
  MD: '2022-11-22',
  ME: '2022-11-22',
  MI: '2022-11-22',
  MO: '2022-11-22',
  MS: '2022-01-01',
  MT: '2022-11-22',
  ND: '2022-11-22',
  NE: '2022-11-22',
  NH: '2022-11-22',
  NM: '2022-11-22',
  PA: '2022-11-22',
  OH: '2022-11-22',
  OK: '2022-11-22',
  OR: '2022-11-22',
  SC: '2022-11-22',
  SD: '2022-11-22',
  TN: '2022-11-22',
  TX: '2022-11-22',
  UT: '2022-01-01',
  VT: '2022-11-22',
  WI: '2022-11-22',
  WV: '2022-11-22'
};

export const tpdcFormStateDates = {
  AL: '2022-11-22',
  AR: '2022-11-22',
  AZ: '2022-11-22',
  CO: '2023-02-15',
  GA: '2022-01-01',
  IA: '2022-11-22',
  ID: '2022-11-22',
  IL: '2022-11-22',
  IN: '2022-11-22',
  KS: '2022-11-22',
  KY: '2022-11-22',
  LA: '2022-11-22',
  MD: '2022-11-22',
  ME: '2022-11-22',
  MI: '2022-11-22',
  MO: '2022-11-22',
  MS: '2022-01-01',
  MT: '2022-11-22',
  ND: '2022-11-22',
  NE: '2022-11-22',
  NH: '2022-11-22',
  NM: '2022-11-22',
  PA: '2022-11-22',
  OH: '2022-11-22',
  OK: '2022-11-22',
  OR: '2022-11-22',
  SC: '2022-11-22',
  SD: '2022-11-22',
  TN: '2022-11-22',
  TX: '2022-11-22',
  UT: '2022-01-01',
  VT: '2022-11-22',
  WI: '2022-11-22',
  WV: '2022-11-22'
};

export const sacnFormStateDates = {
  AL: '2022-11-22',
  AZ: '2022-11-22',
  IA: '2022-11-22',
  ID: '2022-11-22',
  IN: '2022-11-22',
  KS: '2022-11-22',
  KY: '2022-11-22',
  MD: '2022-11-22',
  MI: '2022-11-22',
  MO: '2022-11-22',
  OH: '2022-11-22',
  OK: '2022-11-22',
  OR: '2022-11-22',
  SC: '2022-11-22',
  SD: '2022-11-22',
  TN: '2022-11-22',
  TX: '2022-11-22',
  WI: '2022-11-22',
  WV: '2022-12-05'
};

export const architecturalShinglesStates = {
  ME: true,
  SD: true
};

export const expandedRoofTypeStates = {
  DC: true,
  ME: true,
  ND: true,
  NH: true,
  SD: true,
  VT: true
};

export const excludedDriverSignsStates = {
  UT: true
};

export const wipeOutNonBranchAutoCancelsOnTermByState = {
  VA: 7
};

export const neverWorseCommunityDriveScoreStates = {
  PA: true
};

export const maximumNewHomeDiscountHomeAgeByState = {
  DC: 49,
  ME: 49,
  NH: 49,
  SD: 49,
  VT: 49
};

/**
 * Valid Zip Codes can be represented as either a single value, or "tuples" containing the min & max values of a range.
 * Values are inclusive.
 */
export const validZipCodeRanges: { [index: string]: [[number, number]] } = {
  AL: [[35000, 39999]],
  AR: [[71601, 72999]],
  AZ: [[85001, 86556]],
  CO: [[80001, 82000]],
  DC: [[20001, 29999]],
  FL: [[32000, 34999]],
  GA: [[30000, 39999]],
  IA: [[50001, 52999]],
  ID: [[83000, 84000]],
  IL: [[60001, 62999]],
  IN: [[46001, 47997]],
  KS: [[66000, 69999]],
  KY: [[40001, 43999]],
  LA: [[70000, 71999]],
  MA: [[1001, 5599]],
  MD: [[20331, 21999]],
  ME: [[3901, 4992]],
  MI: [[48001, 49999]],
  MO: [[63001, 65899]],
  MS: [[38601, 39999]],
  MT: [[59001, 59999]],
  ND: [[58000, 58999]],
  NE: [[68001, 69999]],
  NH: [[3000, 3999]],
  NM: [[87001, 89999]],
  OH: [[43001, 45999]],
  OK: [[73001, 74999]],
  OR: [[97000, 97999]],
  PA: [[15001, 19999]],
  SC: [[29001, 29999]],
  SD: [[57001, 57999]],
  TN: [[37000, 39999]],
  TX: [[73301, 88589]],
  UT: [[84000, 84999]],
  VA: [[20000, 24999]],
  VT: [[5000, 5999]],
  WI: [[53001, 55000]],
  WV: [[24000, 26999]],
  WY: [[82001, 83999]]
};

export const umUimCoverageArray = {
  AL: ['UM'],
  AR: ['UMBI', 'UIM'],
  AZ: ['UM_UIM'],
  CO: ['UM_UIM'],
  DC: ['UMBI', 'UIMBI', 'UIM', 'UM'],
  FL: ['UM'],
  GA: ['UM'],
  IA: ['UM', 'UIM'],
  ID: ['UM', 'UIM'],
  IL: ['UMUIM', 'UM_UIM'],
  IN: ['UM_UIM'],
  KS: ['UMUIM'],
  KY: ['UM', 'UIM'],
  LA: ['UMBI'],
  MA: ['UM', 'UIM'],
  MD: ['UM', 'EUIM'],
  ME: ['UMUIM'],
  MO: ['UM', 'UIM'],
  MI: ['UMUIM'],
  MS: ['UM', 'UIM'],
  MT: ['UM', 'UIM'],
  ND: ['UM', 'UIM'],
  NE: ['UM', 'UIM'],
  NH: ['UM', 'UIM'],
  NM: ['UMUIM'],
  OH: ['UM_UIM'],
  OK: ['UM'],
  OR: ['UM'],
  PA: ['UM', 'UIM'],
  SC: ['UM', 'UIM'],
  SD: ['UM', 'UIM'],
  TN: ['UM'],
  TX: ['UMUIMBI', 'UMUIMPD'],
  UT: ['UM', 'UIM'],
  VA: ['UMUIM'],
  VT: ['UM', 'UIM'],
  WI: ['UM', 'UIM'],
  WV: ['UMBI', 'UIMBI'],
  WY: ['UM']
};

export const umUimCoverageArrayAdditions = {
  MD: ['EUIMPD'],
  SC: ['UIMPD'],
  WV: ['UIMPD']
};

export const umPremiumCoverageArray = {
  AL: ['UM_UIM'],
  AR: ['UMBI'],
  AZ: ['UM_UIM'],
  CO: ['UM_UIM'],
  DC: ['UMBI', 'UM'],
  FL: ['UM'],
  GA: ['UM'],
  IA: ['UM'],
  ID: ['UM'],
  IL: ['UMUIM'],
  IN: ['UM_UIM'],
  KS: ['UM'],
  KY: ['UM'],
  LA: ['UMBI'],
  MA: ['UM'],
  MD: ['UM'],
  ME: ['UMUIM'],
  MO: ['UM'],
  MI: ['UMUIM'],
  MS: ['UM'],
  MT: ['UM'],
  ND: ['UM'],
  NE: ['UM'],
  NH: ['UM'],
  NM: ['UMUIM'],
  OH: ['UM_UIM'],
  OK: ['UM'],
  OR: ['UM'],
  PA: ['UM'],
  SC: ['UM'],
  SD: ['UM'],
  TN: ['UM'],
  TX: ['UMUIMBI', 'UMUIMPD'],
  UT: ['UM'],
  VA: ['UMUIM'],
  VT: ['UM'],
  WI: ['UM'],
  WV: ['UMBI'],
  WY: ['UM']
};

export const uimPremiumCoverageArray = {
  AL: [],
  AR: ['UIM'],
  AZ: [],
  CO: [],
  DC: ['UIMBI', 'UIM'],
  FL: [],
  GA: [],
  IA: ['UIM'],
  ID: ['UIM'],
  IL: [],
  IN: [],
  KS: [],
  KY: ['UIM'],
  LA: [],
  MA: ['UIM'],
  MD: ['EUIM'],
  ME: [],
  MO: ['UIM'],
  MI: [],
  MS: ['UIM'],
  MT: ['UIM'],
  ND: ['UIM'],
  NE: ['UIM'],
  NH: ['UIM'],
  NM: [],
  OH: [],
  OK: [],
  OR: [],
  PA: ['UIM'],
  SC: ['UIM'],
  SD: ['UIM'],
  TN: [],
  TX: [],
  UT: ['UIM'],
  VA: [],
  VT: ['UIM'],
  WI: ['UIM'],
  WV: ['UIMBI'],
  WY: []
};

export const stackedUMStates = { NM: true, PA: true };

export const disableExcludeDriverStates = {
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: true,
  GA: true,
  IA: true,
  ID: true,
  IL: true,
  IN: true,
  KS: true,
  KY: true,
  LA: true,
  MA: true,
  ME: true,
  MI: true,
  MO: true,
  MS: true,
  MT: true,
  ND: true,
  NH: true,
  NE: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: true,
  SD: true,
  TN: true,
  TX: true,
  UT: true,
  VA: true,
  VT: true,
  WI: true,
  WV: true,
  WY: true
};

export const noUmbrellaStates = { GA: true, KS: true, MA: true, WV: true };
export const umbrellaStartDateStates = {
  MT: '2022-11-20'
};

export const autoCoveragesToSave = [
  'BI',
  'OBI',
  'RBI',
  'PD',
  'UM',
  'UIM',
  'UMBI',
  'UIMBI',
  'UM_UIM',
  'EUIM',
  'EUIMPD',
  'UMPD',
  'HUMPD',
  'UIMPD',
  'UMUIM',
  'UMUIMBI',
  'UMUIMPD',
  'COMP',
  'COLL',
  'LIM_COLL',
  'MED',
  'PIP',
  'APIP',
  'GPIP',
  'TRNSP',
  'ROAD',
  'ACPE',
  'ACQ_EXP',
  'OPS_EXP',
  'CONTENTS',
  'COMP_TRLR',
  'COLL_TRLR',
  'LOAN',
  'LPD',
  'PPI',
  'ME',
  'WL',
  'ACR',
  'INCM_LOSS',
  'COMBO_FPB',
  'FNRL_BEN',
  'MED_EXP',
  'ACC_DTH',
  'PIP_scenarios',
  'GPIP_scenarios',
  'affinity_discount_savings',
  'total_statutory_otherLiability', // this and the next three needed for stat reporting
  'total_statutory_PIP',
  'total_statutory_physDam',
  'rating_tier',
  'UM_25_50_UMPD_25', // this and the next one are needed for the UMSF form in WV
  'UM_100_300_UMPD_50',
  'affinity_discount',
  'continuous_savings',
  'direct_savings',
  'employee_savings',
  'five_year_savings',
  'good_payer_savings',
  'ho_mh_mc_savings',
  'multi_policy_savings',
  'my_community_discount_savings',
  'paid_eft_acp_savings',
  'paperless_savings',
  'smart_tech_savings',
  'three_year_savings',
  'vouch_for_me_savings'
];

export const autoCoveragesToOmitIfEmpty = [
  'continuous_savings',
  'direct_savings',
  'employee_savings',
  'five_year_savings',
  'good_payer_savings',
  'ho_mh_mc_savings',
  'multi_policy_savings',
  'my_community_discount_savings',
  'paid_eft_acp_savings',
  'paperless_savings',
  'smart_tech_savings',
  'three_year_savings',
  'vouch_for_me_savings',
  'HUMPD'
];

export const homeCoveragesToSave = [
  'acq_cost',
  'affinity_discount',
  'affinity_discount_savings',
  'all_other',
  'animal_exclusion',
  'az_cova_fire_tax_credit',
  'az_spp_fire_tax_credit',
  'claim_free_savings',
  'connected_home_discount',
  'connected_home_discount_savings',
  'covbmt',
  'covbp',
  'covb_1',
  'covb_10',
  'covb_11',
  'covb_12',
  'covb_2',
  'covb_3',
  'covb_4',
  'covb_5',
  'covb_6',
  'covb_7',
  'covb_8',
  'covb_9',
  'covca',
  'covc_1',
  'covc_10',
  'covc_11',
  'covc_12',
  'covc_2',
  'covc_3',
  'covc_4',
  'covc_5',
  'covc_6',
  'covc_7',
  'covc_8',
  'covc_9',
  'covdc',
  'covdr',
  'covd_1',
  'covd_10',
  'covd_11',
  'covd_12',
  'covd_2',
  'covd_3',
  'covd_4',
  'covd_5',
  'covd_6',
  'covd_7',
  'covd_8',
  'covd_9',
  'covd_unltd_diff',
  'coveb',
  'covepae',
  'coverage_a',
  'covf',
  'covfri',
  'covg',
  'covj',
  'covlp',
  'covmi',
  'covost',
  'covost_liab_protection',
  'covost_prop_remediation',
  'covsh',
  'covsl',
  'covsp',
  'covst',
  'covwb',
  'covx',
  'covy',
  'covyg',
  'cov_ms_premium',
  'direct_savings',
  'employee_savings',
  'fire_house',
  'fire_occupant',
  'fixed_exp_prem',
  'hail',
  'host_protection',
  'hurricane',
  'imagery_collection_savings',
  'liability',
  'lightning',
  'multi_policy_savings',
  'my_community_discount_savings',
  'ncor',
  'new_home_savings',
  'non_weather_water',
  'paperless_savings',
  'personal_umbrella_liability_total',
  'preferred_payer_savings',
  'rrp',
  'spp_business',
  'spp_camera',
  'spp_coin',
  'spp_deep_sea',
  'spp_fine_arts',
  'spp_fishing_other',
  'spp_furs',
  'spp_golf',
  'spp_jewelry',
  'spp_jewelry_gemprint',
  'spp_musical_nonpro',
  'spp_musical_pro',
  'spp_silverware',
  'spp_sports_other',
  'spp_stamp',
  'spp_total',
  'spp_us_guns',
  'spp_world_guns',
  'theft',
  'total_increase_limits_addends',
  'vouch_for_me_savings',
  'weather_water',
  'wildfire',
  'wind',
  'bc_0_prem_diff',
  'bc_5_prem_diff',
  'bc_10_prem_diff',
  'bc_20_prem_diff',
  'ibsc_0_prem_diff',
  'ibsc_10_prem_diff',
  'ibsc_20_prem_diff',
  'ibsc_40_prem_diff'
];

export const homeCoveragesToOmitIfEmpty = [
  'direct_savings',
  'employee_savings',
  'my_community_discount_savings',
  'paperless_savings',
  'vouch_for_me_savings'
];

export const rentersCoveragesToSave = [
  'direct_savings',
  'paperless_savings',
  'employee_savings',
  'affinity_discount_savings',
  'connected_home_discount_savings',
  'my_community_discount_savings',
  'vouch_for_me_savings',
  'spp_jewelry',
  'spp_jewelry_gemprint',
  'spp_furs ',
  'spp_camera',
  'spp_musical_nonpro',
  'spp_musical_pro',
  'spp_silverware',
  'spp_fine_arts',
  'spp_golf',
  'spp_us_guns',
  'spp_world_guns',
  'spp_deep_sea',
  'spp_fishing_other',
  'spp_sports_other',
  'spp_stamp',
  'spp_coin',
  'spp_business',
  'renters_fixed_expense',
  'personal_property_protection',
  'additional_living_expense',
  'family_liability_protection',
  'guest_medical_protection'
];

export const rentersCoveragesToOmitIfEmpty = [
  'direct_savings',
  'vouch_for_me_savings',
  'employee_savings',
  'paperless_savings',
  'my_community_discount_savings',
  'spp_jewelry',
  'spp_jewelry_gemprint',
  'spp_furs ',
  'spp_camera',
  'spp_musical_nonpro',
  'spp_musical_pro',
  'spp_silverware',
  'spp_fine_arts',
  'spp_golf',
  'spp_us_guns',
  'spp_world_guns',
  'spp_deep_sea',
  'spp_fishing_other',
  'spp_sports_other',
  'spp_stamp',
  'spp_coin',
  'spp_business',
  'renters_fixed_expense',
  'personal_property_protection',
  'additional_living_expense',
  'family_liability_protection',
  'guest_medical_protection'
];

// @note assuming the same as home for now
export const condoCoveragesToSave = [
  'claim_free_savings',
  'new_home_savings',
  'connected_home_discount_savings',
  'multi_policy_savings',
  'preferred_payer_savings',
  'employee_savings',
  'direct_savings',
  'paperless_savings',
  'my_community_discount_savings',
  'affinity_discount_savings',
  'coverage_C',
  'QUOTE_TOTAL_ID',
  'covA',
  'covD',
  'covX',
  'covY',
  'covG',
  'total_increase_limits_addends',
  'covBP',
  'covCA',
  'covDR',
  'covEB',
  'covF',
  'covJ',
  'covMI',
  'cov_ms_premium',
  'covSP',
  'covST',
  'covEPAE',
  'covWB',
  'animal_exclusion',
  'personal_umbrella_liability_total',
  'spp_jewelry',
  'spp_jewelry_gemprint',
  'spp_furs',
  'spp_camera',
  'spp_musical_nonpro',
  'spp_musical_pro',
  'spp_silverware',
  'spp_fine_arts',
  'spp_golf',
  'spp_us_guns',
  'spp_world_guns',
  'spp_deep_sea',
  'spp_fishing_other',
  'spp_sports_other',
  'spp_stamp',
  'spp_coin',
  'spp_business'
];

export const condoCoveragesToOmitIfEmpty = [
  'direct_savings',
  'employee_savings',
  'my_community_discount_savings',
  'paperless_savings',
  'vouch_for_me_savings'
];

export const vouchDiscountStates = {
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: true,
  GA: true,
  MD: true,
  MO: true,
  IA: true,
  IL: true,
  IN: true,
  KS: true,
  KY: true,
  LA: true,
  MA: true,
  ME: true,
  MI: true,
  MS: true,
  MT: true,
  ND: true,
  NE: true,
  NH: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: false,
  SD: true,
  TN: true,
  TX: false,
  UT: true,
  VA: true,
  VT: true,
  WI: true,
  WV: true,
  WY: true
};

export const vouchDiscountStateCaps = {
  AL: 5,
  AR: 5,
  AZ: 5,
  CO: 5,
  DC: 5,
  GA: 5,
  MD: 5,
  MO: 5,
  IA: 5,
  ID: 5,
  IL: 5,
  IN: 5,
  KS: 5,
  KY: 5,
  LA: 0,
  MA: 5,
  ME: 5,
  MI: 5,
  MS: 5,
  MT: 5,
  ND: 5,
  NH: 5,
  NM: 5,
  OH: 5,
  OK: 5,
  OR: 5,
  SC: 0,
  SD: 5,
  TN: 5,
  TX: 0,
  UT: 5,
  VA: 5,
  VT: 5,
  WI: 5,
  WV: 5,
  WY: 5
};

export const myCommunityDiscountStateCaps = {
  AR: 0,
  AZ: 25,
  IA: 0,
  ID: 0,
  IL: 0,
  IN: 0,
  KY: 0,
  LA: 0,
  MO: 50,
  MS: 0,
  MT: 0,
  NH: 0,
  NM: 0,
  OH: 0,
  SD: 0,
  TX: 0,
  VT: 0,
  WV: 0
};

export const myCommunityDiscountVerbalReferralStates = ['IA', 'NM', 'NE', 'OH'];

export const alabamaLogicInstallmentFeeStates = {
  AL: true
};

export const fixedHomeInstallmentFeeStates = {
  LA: true
};

export const courseDiscountTypeByStates = {
  AL: courseDiscountTypes.SENIOR,
  AR: courseDiscountTypes.SENIOR,
  AZ: false,
  CO: courseDiscountTypes.SENIOR,
  DC: courseDiscountTypes.SENIOR,
  GA: false,
  IA: false,
  ID: courseDiscountTypes.SENIOR,
  IL: courseDiscountTypes.SENIOR,
  IN: false,
  KS: courseDiscountTypes.AVOIDANCE,
  KY: courseDiscountTypes.SENIOR,
  LA: false,
  MA: courseDiscountTypes.INEXPERIENCED,
  ME: courseDiscountTypes.SENIOR,
  MI: false,
  MO: false,
  MS: courseDiscountTypes.SENIOR,
  MT: courseDiscountTypes.SENIOR,
  ND: false,
  NH: false,
  NE: false,
  NM: courseDiscountTypes.SENIOR,
  OH: courseDiscountTypes.SENIOR,
  OK: courseDiscountTypes.DEFENSIVE,
  OR: courseDiscountTypes.SENIOR,
  PA: courseDiscountTypes.DEFENSIVE,
  SC: courseDiscountTypes.DEFENSIVE,
  SD: courseDiscountTypes.SENIOR,
  TN: courseDiscountTypes.SENIOR,
  TX: false,
  UT: courseDiscountTypes.SENIOR,
  VT: false,
  WI: false,
  WV: courseDiscountTypes.SENIOR,
  WY: courseDiscountTypes.SENIOR
};

export const additionalDriverDiscountsTypesByStates = {
  // this allows for only one additional discount, will need refactor if we end up with states where driver can have 3+ discounts offered
  KY: {
    condition: 'IS_ACTIVE_DUTY',
    discountType: courseDiscountTypes.MILITARY_DEFENSIVE
  },
  MT: {
    condition: 'IS_ACTIVE_DUTY_NG',
    discountType: courseDiscountTypes.NATIONAL_GUARD
  }
};

export const stateSpecificToolTips = {
  KY: 'discountCourseKY',
  MA: 'inexperiencedCourseMA'
};

export const seniorDiscountByAgeOnlyStates = {
  MI: 65,
  KY: 55
};

export const lapsedLicenseMonthsStates = {
  MA: 12
};

export const courseDiscountsAges = {
  AL: 55,
  AR: 55,
  CO: 55,
  DC: 50,
  ID: 55,
  IL: 60,
  KS: 1,
  KY: 55,
  MA: 7, // To be used as: age - ageFirstLicensed < courseDiscountsAges[state]
  ME: 55,
  MS: 55,
  MT: 55,
  NM: 55,
  OH: 60,
  OK: 1,
  OR: 55,
  PA: 55,
  SC: 1,
  SD: 55,
  TN: 55,
  UT: 55,
  VA: 60,
  WV: 55,
  WY: 55
};

export const zendeskInfoByStateWithKeys = {
  KY: {
    IS_ACTIVE_DUTY:
      'has taken a voluntary accident prevention course provided by the Armed Forces in the last 5 years.',
    SENIOR:
      'has taken an Accident Prevention Course approved by the Transportation Cabinet of Kentucky within the last 5 years.'
  },
  MA: {
    [courseDiscountTypes.INEXPERIENCED]: `has taken a Driver Training Course in the last ${courseDiscountsAges.MA} years`
  },
  MT: {
    IS_ACTIVE_DUTY_NG: 'has taken a voluntary, state-approved defensive driving course.',
    SENIOR: 'has taken a state-approved defensive driving course.'
  }
};

export const directAffinityCodes = ['KST1', 'SMAFIN1', 'QWZ1'];
export const partnerMonolineRejectionAffinityCodes = ['ORL1', 'ORL2', 'ORL3', 'ORL4', 'ORLE'];

export const statesWith250PD = ['AL', 'GA', 'IN', 'KS', 'MA', 'MD', 'MS', 'OH', 'PA', 'SC', 'WI', 'IA'];

export const hurricaneDeductibleStates = {
  AZ: false,
  CO: false,
  IL: false,
  IN: false,
  KS: false,
  LA: true,
  MA: true,
  MO: false,
  OH: false,
  TX: false
};

export const sR22States = { NH: true };
export const sR22TicketEmail = 'stateverifications@ourbranch.com';

export const mandatoryWindHailDeductible = {};
export const limitedBranchWindhailCoverage = { AL: true, GA: true };

export const homeownersRights = {
  AL: 'https://www.aldoi.gov/PDF/Consumers/HomeownersBillofRightsAct.pdf'
};

/** States that, when they have a minimum % deductible, only allow % deductibles */
export const percentageWindHailDeductible = { MD: true };
export const mandatoryPolicyUMPD = { MD: true, VT: true };
export const umpdWithCollisionStates = ['ID', 'IL', 'IN', 'OR'];
export const mandatoryUM = { IL: true, MA: true, MD: true, ME: true, MO: true, ND: true, OR: true, SD: true, VT: true };
export const mandatoryPIP = { KS: true, MD: true, ND: true, OR: true, UT: true };
export const noUDRsAllowedStates = ['IN', 'MD'];
export const noStrictCleanUWStates = ['MD', 'MI', 'ME', 'TX', 'NH'];
export const noExcludedDriversStates = [
  'AL',
  'AR',
  'AZ',
  'CO',
  'DC',
  'GA',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'ME',
  'MI',
  'MO',
  'MS',
  'MT',
  'ND',
  'NH',
  'NE',
  'NM',
  'OH',
  'OK',
  'OR',
  'PA',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WI',
  'WV',
  'WY'
];
export const noUDRInMonarchStates = ['MS', 'MT'];
export const specificExcludedDriversStates = ['IA'];
export const limitedCollisionStates = { MA: true };

export const actsOfGodLossTypes = ['FLOOD', 'HAIL', 'LIGHT', 'MOVE', 'QUAKE', 'SINK', 'VOLCANO', 'WEATHER', 'WIND'];

export const noActsOfGodStates = {
  CT: true,
  MS: true,
  MT: true,
  SC: true
};

export const noFirstActsOfGodStates = {
  GA: true
};

export const windHailLightningLossTypes = ['HAIL', 'LIGHT', 'WIND'];

export const noWindHailLightningStates = {
  AR: true,
  TN: true
};

export const noFirstWindHailLightningLossTypesStates = {
  TX: true
};

export const SouthDakotaUMBIRulesStates = {
  SD: true
};

export const UMBIEqualsBIStates = {
  NH: true
};

export const UIMBIEqualsUMBIUnlessRejectStates = {
  AR: true,
  MA: true,
  MT: true
};

export const BI125CSLStates = {
  ME: true
};

export const noCSLStates = {
  MA: true
};

export const combinedUMUIM = {
  AL: true,
  AR: false,
  AZ: false,
  CO: true,
  DC: false,
  GA: true,
  IA: true,
  ID: false,
  IL: true,
  IN: true,
  KS: true,
  LA: true,
  MA: false,
  MD: true,
  ME: true,
  MI: true,
  MO: false,
  MS: true,
  MT: false,
  ND: true,
  NE: true,
  NH: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: false,
  SC: false,
  SD: true,
  TN: true,
  TX: true,
  UT: false,
  VA: true,
  VT: true,
  WI: false,
  WV: false,
  WY: true
};

export const branchClaimCountJustAAFStates = { MS: true, OR: true };
export const separate3NAFsCheckStates = { MD: true };
export const separate3AAFsAndViolationsCheckStates = { MD: true };
export const policyLevelUMPDStates = {
  AR: true,
  DC: true,
  GA: true,
  IN: true,
  MD: true,
  MS: true,
  SC: true,
  TN: true,
  TX: true,
  VA: true,
  VT: true,
  WV: true,
  WY: true
};
export const policyLevelUMPDInUMSelectionStates = {
  DC: true,
  SC: true,
  WV: true
};

export const allow50100UMBIon2550BIStates = {
  VT: true
};

export const printUMPDAtPolicyLevelStates = {
  TN: true,
  VA: true
};

export const UMPDOnlyWithUMBIStates = {
  AR: true,
  GA: true,
  ID: true,
  UT: true,
  MD: true,
  TN: true,
  TX: true,
  WY: true
};
export const noUMPDStates = {
  AL: true,
  AZ: true,
  IA: true,
  KS: true,
  MA: true,
  KY: true,
  ME: true,
  MI: true,
  MT: true,
  ND: true,
  NH: true,
  PA: true,
  SD: true,
  WY: true
};

export const quinstreetStates = {
  AL: true,
  ID: true,
  IA: true,
  KS: true,
  NE: true,
  NH: true,
  NM: true,
  ND: true,
  SD: true,
  TN: true,
  UT: true,
  WV: true,
  WY: true
};

export const noMedPayStates = { AR: true, DC: true, MI: true, MD: true, ND: true, OR: true, UT: true };

export const doNotShowCarLevelUMPDStates = {
  ...policyLevelUMPDStates,
  ...noUMPDStates
};

export const defaultCommunityDriveStates = { AZ: true, CO: true };
export const enhancedRoofWindstormStates = { SC: true, LA: true };
export const coverageRSExtendedForcedStates = { DC: true, ND: true, NH: true, NM: true, VA: true, VT: true, WV: true };
export const coverageRSForcedStates = {};
// When adding a new state to windstormMitigationDiscountStates, also add the options to windstormMitigationLevelValues.json
export const windstormMitigationDiscountStates = { AL: true, MS: true };
export const windstormMitigationDiscountYearBuilt = 2006;
export const windstormMitigationDefinitionsLinkStates = {
  MS: 'https://app.getguru.com/card/ijAxGaRT/Mississippi-Wind-Mitigation-Discount-Definitions'
};

export const pullAutoDataOnlyForAutoPolicyStates = {
  AK: true,
  DC: true,
  GA: true,
  MA: true,
  ND: true,
  NH: true,
  SC: true,
  VT: true
};

/** CCW violation type */
export const CCWStates = { AL: true };
export const excessCarsByDriverCarAssignmentStates = { VA: true };
export const vermontExcessDriversRatingRules = { VT: true };
export const assignDriversToCarsStates = { VA: true, VT: true, MA: true };
export const minPremiumCheckStates = { VA: 10 };
export const altCreditAlgorithmStates = {
  KS: true,
  FL: true,
  SC: true
};
export const noCreditStates = {
  MA: true
};

export const driverViolationsDisqualifyingCourseDiscountStates = {
  AL: ['AAF', 'CRD', 'DR', 'FEL', 'FLE', 'HOM', 'LDL', 'LTS', 'MAJ', 'RKD', 'SUS', 'DWI'],
  MS: ['AAF', 'DWI', 'MAJ'],
  MT: ['AAF', 'CRD', 'DR', 'FEL', 'FLE', 'HOM', 'LDL', 'LTS', 'MAJ', 'MMV', 'RKD', 'SPD', 'SUS', 'DWI']
};

export const noPPCExclusionStates = { KY: true, MI: true, NH: true };
export const stateSpecificAutoUnderwriting = { MI: true };

// THIS SHOULD ONLY BE USED FOR TESTING PURPOSES WHEN WE ARE NOT YET LIVE TO PULL
// FPC/PPC in A STATE:
// Example value: { MS: { fpc: '9', county: 'CALHOUN' } }
export const rewriteFPCForTesting = {};

export const ineligibleForUWHomeClaimGroupStates = {
  MD: ['F', 'G']
};

// Note: Here are the DTC values translated to distance
// 01: 0 to 500 ft, 02: 501 to 1000, 03: 1001 to 1500, 04: 1501 to 2000, 05: 2001 to 2500
// 06: 2501 ft to less than 1 mi, 07: 1 to <2mi, 08: 2 to <3mi, 09: 3 to <4mi, 10: 4 to < 5mi
// 11: 5 to <10mi, 12: 10 to <20, 13: 15mi to <20, 14: 20mi to <25, 15: 25mi to <30, 16: 30mi +

export const ineligibleDistanceToOceanCodesStates = {
  MA: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  MD: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  ME: ['01', '02', '03', '04', '05', '1', '2', '3', '4', '5'],
  MS: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  VA: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  FL: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
};

export const windHailExclusionDistanceToOceanCodesStates = {
  GA: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  MD: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  ME: ['01', '02', '03', '04', '05', '1', '2', '3', '4', '5'],
  MS: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
  VA: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6']
};

export const windHailTwoPercentMinimumDeductibleCodes = ['07', '08', '09', '10', '7', '8', '9'];

export const disqualifyingChargesByState = {
  MD: ['CRD', 'DR', 'DWI', 'FDL', 'FEL', 'FLE', 'FSV', 'HOM', 'LDL', 'LTS', 'RKD', 'SLV', 'SUS'],
  MI: ['CRD', 'DR', 'DWI', 'FEL', 'FLE', 'FSV', 'HOM', 'LDL', 'LTS', 'RKD', 'SLV', 'SUS']
};

export const ratingLookbackByState = {
  KS: 35,
  MD: 35,
  PA: 35
};

export const maxValueForAFNClassification = {
  PA: [
    { startDateAsInt: 19000101, value: 1900 },
    {
      startDateAsInt: 20230701,
      value: 2100
    }
  ]
};

export const neverLoseCommunityDriveParticipationByState = {
  PA: true
};

export const neverLoseUmbrellaByState = {
  PA: true
};

export const yearBuiltMinimum = {
  MI: 1600
};

export const homeYearBuiltMinimum = {
  AL: 2013,
  AR: 2013,
  AZ: 2013,
  CO: 2013,
  DC: 2013,
  GA: 2013,
  IA: 2013,
  ID: 2013,
  IL: 2013,
  IN: 2013,
  KS: 2013,
  KY: 2013,
  LA: 2013,
  MA: 2013,
  MI: 1600,
  MO: 2013,
  MS: 2013,
  MT: 2013,
  ND: 2013,
  NE: 2013,
  NM: 2013,
  OH: 2013,
  OK: 2013,
  OR: 2013,
  PA: 2013,
  SC: 2013,
  SD: 2013,
  TN: 2013,
  UT: 2013,
  VT: 2013,
  WI: 2013,
  WV: 2013,
  WY: 2013
};

export const rceLimitOnline = {
  AL: 950000,
  AR: 950000,
  AZ: 950000,
  CO: 950000,
  DC: 950000,
  FL: 950000,
  GA: 950000,
  IA: 950000,
  ID: 950000,
  IL: 950000,
  IN: 950000,
  KS: 950000,
  KY: 950000,
  LA: 950000,
  MA: 950000,
  MD: 950000,
  ME: 950000,
  MI: 950000,
  MO: 950000,
  MS: 950000,
  MT: 950000,
  ND: 950000,
  NE: 950000,
  NH: 950000,
  NM: 950000,
  OH: 950000,
  OR: 950000,
  PA: 950000,
  SC: 950000,
  SD: 950000,
  TN: 950000,
  TX: 850000,
  UT: 950000,
  VA: 950000,
  VT: 950000,
  WI: 950000,
  WV: 950000,
  WY: 950000
};

export const rceMinimum = {
  MI: 50000
};

// These zero out violations of these types so they cannot be used at all for rating
export const autoViolationNoRateCodes = {
  IA: ['NAF', 'CMP', 'CMU'],
  MO: ['PUN', 'NAF', 'CMP', 'CMU'],
  MT: ['PUN', 'NAF', 'CMP', 'CMU'],
  ND: ['NAF', 'CMP', 'CMU'],
  NH: ['NAF', 'CMP', 'CMU'],
  TN: ['CMP', 'CMU'],
  TX: ['PUN', 'CMP', 'CMU'],
  VT: ['ANO', 'ANC', 'PUA', 'NAF', 'CMP', 'CMU'],
  WY: ['CMP', 'CMU']
};

// where we cannot use Branch claims for 3/5 year discounts
export const companyNoRateCodesForDiscounts = {
  AL: ['NAF'],
  AR: ['NAF'],
  AZ: ['NAF'],
  CO: ['NAF'],
  DC: ['NAF'],
  GA: ['NAF'],
  IL: ['NAF'],
  KS: ['NAF'],
  MD: ['NAF'],
  MI: ['NAF'],
  MO: ['NAF'],
  MS: ['NAF'],
  MT: ['NAF'],
  ND: ['NAF'],
  NH: ['NAF'],
  OK: ['NAF'],
  SC: ['NAF'],
  SD: ['NAF'],
  TN: ['NAF'],
  UT: ['NAF'],
  VT: ['NAF'],
  WI: ['NAF'],
  WY: ['NAF']
};

export const waterBackupNameByState = {
  MD: 'Sewer Backup'
};

export const waterBackupNameIncludedIncreasedByState = {
  VA: true
};

export const waterBackupDeductibleStates = {
  DC: true,
  ME: true,
  ND: true,
  NH: true,
  SD: true
};

export const waterBackupDeductibleFixedStates = {
  OR: 1000
};

export const coverageBPInsideLimitStates = {
  DC: 2500,
  KS: 2500,
  MA: 2500,
  ND: 2500,
  SC: 2500
};

export const sewerBackupStates = {
  MD: true
};

export const roofSurfacesExtendedCompositionMaxAge = {
  AL: 15,
  AR: 15,
  AZ: 15,
  CO: 15,
  DC: 15,
  GA: 15,
  IA: 15,
  ID: 15,
  IL: 15,
  IN: 15,
  KS: 15,
  KY: 15,
  LA: 15,
  MA: 15,
  MD: 15,
  ME: 15,
  MI: 15,
  MO: 10,
  MS: 15,
  MT: 15,
  ND: 15,
  NE: 15,
  NM: 15,
  OH: 15,
  OK: 15,
  OR: 15,
  PA: 15,
  SC: 15,
  SD: 15,
  TN: 15,
  TX: 15,
  UT: 15,
  VA: 15,
  VT: 15,
  WI: 15,
  WV: 15,
  WY: 15
};

export const invalidLicenseStatuses = [
  'CANCELL',
  'C',
  'CAN',
  'CAN,EX',
  'CLCSOR',
  'CN',
  'CNCTMP',
  'CS',
  'CSOR',
  'DECEAS',
  'DECEASE',
  'DRVEXP',
  'DRVSUS',
  'EX',
  'EXPIRE',
  'EXPIRED',
  'FAILURE',
  'INACTIV',
  'INVALID',
  'NL',
  'NON',
  'NON-LIC',
  'NOT ACT',
  'NOT LIC',
  'NOT VAL',
  'NOTLIC',
  'REVOKED',
  'S',
  'SUR',
  'SURREND',
  'SUSP/NO',
  'SUSPEND',
  'SUSPND',
  'VOL SUR'
];
export const mvrStates = ['CA', 'MO', 'MN', 'OH'];
// these are the states in which we want to force pulling MVRs before binding
export const preBindMVRStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'NM',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

export const driversLicenseNotRequiredStates = [
  'AK',
  'AZ',
  'CA',
  'CT',
  'FL',
  'GA',
  'HI',
  'IA',
  'IL',
  'IN',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'WA',
  'WI',
  'WY'
];

export const fcraDriverRiskStates = [
  'AK',
  'AZ',
  'CT',
  'FL',
  'GA',
  'HI',
  'IA',
  'IL',
  'IN',
  'MD',
  'ND',
  'NJ',
  'NV',
  'OK',
  'OR',
  'PA',
  'SD',
  'TN',
  'TX',
  'UT',
  'WA',
  'WI',
  'WY'
];

export const driverCarAssignmentStates = {
  MA: true,
  VA: true,
  VT: true
};

export const claimLimitRentalStates = {
  VA: true
};

export const defaultACPELimitStates = {
  FL: 2500
};

export const minLimitRentalStates = {
  AL: 40,
  AR: 40,
  AZ: 40,
  CO: 40,
  DC: 40,
  GA: 40,
  IA: 40,
  ID: 40,
  IL: 40,
  IN: 40,
  KS: 40,
  KY: 40,
  LA: 40,
  MA: 40,
  MD: 40,
  ME: 40,
  MI: 40,
  MO: 40,
  MS: 40,
  MT: 40,
  ND: 40,
  NE: 40,
  NH: 40,
  NM: 40,
  OH: 40,
  OK: 40,
  OR: 40,
  PA: 40,
  SC: 40,
  SD: 40,
  TN: 40,
  TX: 40,
  UT: 40,
  VA: 600,
  VT: 40,
  WI: 40,
  WV: 40,
  WY: 40
};

export const allowInstallPayOnHomePoliciesStates = {
  KY: true,
  LA: true
};

export const onlyPayInFullOnAutoPoliciesStates = {
  MD: true,
  PA: true
};

export const basementTypes = {
  Slab: 'S',
  Raised: 'R',
  Unfinished: 'U',
  PartialFinished: 'PF',
  FullFinished: 'FF'
};

export const windHailDeductibleDefaultStates = {
  MO: 0.01,
  OK: 0.01,
  SD: 0.01
};

export const allOtherPerilsDeductibleDefaultStates = {
  AL: 2000,
  AK: 2000,
  AZ: 2000,
  AR: 2000,
  CA: 2000,
  CO: 2000,
  CT: 2000,
  DE: 2000,
  DC: 2000,
  FL: 2000,
  GA: 2000,
  HI: 2000,
  ID: 2000,
  IL: 2000,
  IN: 2000,
  IA: 2000,
  KS: 2000,
  LA: 2000,
  MA: 2000,
  MI: 2000,
  MN: 2000,
  MS: 2000,
  MO: 2000,
  MT: 2000,
  NE: 2000,
  NV: 2000,
  NJ: 2000,
  NM: 2000,
  NY: 2000,
  NC: 2000,
  ND: 2000,
  OH: 2000,
  OK: 2000,
  OR: 2000,
  PA: 2000,
  RI: 2000,
  SC: 2000,
  SD: 2000,
  TN: 2000,
  TX: 2000,
  UT: 2000,
  VT: 2000,
  VA: 2000,
  WA: 2000,
  WV: 2000,
  WI: 2000,
  WY: 2000
};

export const uninsuredMotoristDefaultStates = {
  VT: '1000000'
};

export const uninsuredMotoristFixedStates = {
  VT: '1000000'
};

export const coverageIBSCDefaultStates = {
  AR: 0,
  IA: 20,
  LA: 20,
  MS: 20,
  SD: 20
};

export const coverageBPDefaultStates = {
  DC: 2500,
  KS: 2500,
  MA: 2500,
  ND: 2500,
  SC: 2500
};

export const coverageDPctOfADefaultStates = {
  DC: 10,
  LA: 10,
  ME: 10,
  MS: 10,
  ND: 10,
  VT: 10
};

export const coverageFDefaultStates = {};

export const coverageJDefaultStates = {
  DC: 1000,
  ME: 1000,
  ND: 1000,
  NH: 1000,
  SD: 1000,
  VT: 1000
};

export const coverageSTDefaultStates = {
  SD: 0,
  VT: 0
};

export const medPayDefaultStates = {
  FL: '1000'
};

export const canRejectPIPStates = ['KY'];

export const pipDefaultStates = {
  AR: 'NONE',
  DC: 'NONE',
  FL: '1000|VA02',
  KS: '4500',
  KY: '10000/0DED',
  MA: '250HH',
  ND: '30',
  OR: 'NONE'
};

export const guestPIPDefaultStates = {
  KY: 'NONE'
};

export const noFaultPIPDefaultStates = {
  KY: 'INCLUDED'
};

export const policyLimitUMPDDefaultStates = {
  VA: '20000'
};

export const leaseLoanStates = {
  AL: { isBix: true, isNotBix: true },
  AR: { isBix: true, isNotBix: true },
  AZ: { isBix: true, isNotBix: false },
  CO: { isBix: true, isNotBix: true },
  DC: { isBix: true, isNotBix: true },
  GA: { isBix: true, isNotBix: true },
  IA: { isBix: true, isNotBix: true },
  ID: { isBix: true, isNotBix: true },
  IL: { isBix: true, isNotBix: false },
  IN: { isBix: true, isNotBix: true },
  KS: { isBix: true, isNotBix: true },
  KY: { isBix: true, isNotBix: true },
  LA: { isBix: false, isNotBix: false },
  MA: { isBix: true, isNotBix: true },
  MD: { isBix: true, isNotBix: true },
  ME: { isBix: true, isNotBix: true },
  MI: { isBix: true, isNotBix: true },
  MO: { isBix: true, isNotBix: false },
  MS: { isBix: true, isNotBix: true },
  MT: { isBix: true, isNotBix: true },
  ND: { isBix: true, isNotBix: true },
  NE: { isBix: true, isNotBix: true },
  NH: { isBix: true, isNotBix: true },
  NM: { isBix: true, isNotBix: true },
  OH: { isBix: true, isNotBix: false },
  OK: { isBix: true, isNotBix: true },
  OR: { isBix: true, isNotBix: true },
  PA: { isBix: true, isNotBix: true },
  SC: { isBix: true, isNotBix: true },
  SD: { isBix: true, isNotBix: true },
  TN: { isBix: true, isNotBix: true },
  TX: { isBix: true, isNotBix: false },
  UT: { isBix: true, isNotBix: true },
  VT: { isBix: true, isNotBix: true },
  WI: { isBix: true, isNotBix: true },
  WV: { isBix: true, isNotBix: true },
  WY: { isBix: true, isNotBix: true }
};

export const advancedConnectedHomeStates = {
  AL: { isBix: true, isNotBix: true },
  AR: { isBix: true, isNotBix: true },
  AZ: { isBix: true, isNotBix: false },
  CO: { isBix: true, isNotBix: true },
  DC: { isBix: true, isNotBix: true },
  GA: { isBix: true, isNotBix: true },
  IA: { isBix: true, isNotBix: true },
  ID: { isBix: true, isNotBix: true },
  IL: { isBix: true, isNotBix: false },
  IN: { isBix: true, isNotBix: false },
  KS: { isBix: true, isNotBix: true },
  KY: { isBix: true, isNotBix: true },
  LA: { isBix: true, isNotBix: true },
  MA: { isBix: true, isNotBix: true },
  MD: { isBix: true, isNotBix: true },
  ME: { isBix: true, isNotBix: true },
  MI: { isBix: true, isNotBix: true },
  MO: { isBix: true, isNotBix: false },
  MS: { isBix: true, isNotBix: true },
  MT: { isBix: true, isNotBix: true },
  ND: { isBix: true, isNotBix: true },
  NE: { isBix: true, isNotBix: true },
  NH: { isBix: true, isNotBix: true },
  NM: { isBix: true, isNotBix: true },
  OH: { isBix: true, isNotBix: false },
  OK: { isBix: true, isNotBix: false },
  OR: { isBix: true, isNotBix: true },
  PA: { isBix: true, isNotBix: true },
  SC: { isBix: true, isNotBix: true },
  SD: { isBix: true, isNotBix: true },
  TN: { isBix: true, isNotBix: true },
  TX: { isBix: true, isNotBix: false },
  UT: { isBix: true, isNotBix: true },
  VT: { isBix: true, isNotBix: true },
  VA: { isBix: true, isNotBix: true },
  WI: { isBix: true, isNotBix: true },
  WV: { isBix: true, isNotBix: true },
  WY: { isBix: true, isNotBix: true }
};

export const discountInventoryScoreStates = {
  AL: { isBix: true, isNotBix: true },
  AR: { isBix: true, isNotBix: true },
  AZ: { isBix: true, isNotBix: false },
  CO: { isBix: true, isNotBix: true },
  DC: { isBix: true, isNotBix: true },
  GA: { isBix: true, isNotBix: true },
  IA: { isBix: true, isNotBix: true },
  ID: { isBix: true, isNotBix: true },
  IL: { isBix: true, isNotBix: true },
  IN: { isBix: true, isNotBix: false },
  KS: { isBix: true, isNotBix: true },
  KY: { isBix: true, isNotBix: true },
  LA: { isBix: true, isNotBix: true },
  MA: { isBix: false, isNotBix: false },
  MD: { isBix: true, isNotBix: true },
  ME: { isBix: true, isNotBix: true },
  MI: { isBix: true, isNotBix: true },
  MO: { isBix: true, isNotBix: false },
  MS: { isBix: true, isNotBix: true },
  MT: { isBix: true, isNotBix: true },
  ND: { isBix: true, isNotBix: true },
  NE: { isBix: true, isNotBix: true },
  NH: { isBix: true, isNotBix: true },
  NM: { isBix: true, isNotBix: true },
  OH: { isBix: true, isNotBix: false },
  OR: { isBix: true, isNotBix: true },
  OK: { isBix: true, isNotBix: false },
  PA: { isBix: true, isNotBix: true },
  SC: { isBix: true, isNotBix: true },
  SD: { isBix: true, isNotBix: true },
  TN: { isBix: true, isNotBix: true },
  TX: { isBix: true, isNotBix: false },
  UT: { isBix: true, isNotBix: true },
  VT: { isBix: true, isNotBix: true },
  VA: { isBix: true, isNotBix: true },
  WI: { isBix: true, isNotBix: true },
  WV: { isBix: true, isNotBix: true },
  WY: { isBix: true, isNotBix: true }
};

export const discountPaperlessStates = {
  AL: { isBix: true, isNotBix: true },
  AR: { isBix: true, isNotBix: true },
  AZ: { isBix: true, isNotBix: false },
  CO: { isBix: true, isNotBix: true },
  DC: { isBix: true, isNotBix: true },
  GA: { isBix: true, isNotBix: true },
  IA: { isBix: true, isNotBix: true },
  ID: { isBix: true, isNotBix: true },
  IL: { isBix: true, isNotBix: false },
  IN: { isBix: true, isNotBix: false },
  KS: { isBix: true, isNotBix: true },
  KY: { isBix: true, isNotBix: true },
  LA: { isBix: true, isNotBix: true },
  MA: { isBix: true, isNotBix: true },
  ME: { isBix: true, isNotBix: true },
  MD: { isBix: true, isNotBix: true },
  MI: { isBix: true, isNotBix: true },
  MO: { isBix: true, isNotBix: false },
  MS: { isBix: true, isNotBix: true },
  MT: { isBix: true, isNotBix: true },
  ND: { isBix: true, isNotBix: true },
  NE: { isBix: true, isNotBix: true },
  NH: { isBix: true, isNotBix: true },
  NM: { isBix: true, isNotBix: true },
  OH: { isBix: true, isNotBix: false },
  OK: { isBix: true, isNotBix: false },
  OR: { isBix: true, isNotBix: true },
  PA: { isBix: true, isNotBix: true },
  SC: { isBix: true, isNotBix: true },
  SD: { isBix: true, isNotBix: true },
  TN: { isBix: true, isNotBix: true },
  TX: { isBix: true, isNotBix: false },
  UT: { isBix: true, isNotBix: true },
  VA: { isBix: true, isNotBix: true },
  VT: { isBix: true, isNotBix: true },
  WI: { isBix: true, isNotBix: true },
  WV: { isBix: true, isNotBix: true },
  WY: { isBix: true, isNotBix: true }
};

export const pipStates = {
  AR: true,
  DC: false,
  FL: true,
  KS: true,
  KY: true,
  MA: true,
  MD: true,
  ND: true,
  OR: true,
  TX: true,
  UT: true
};

export const accidentalDeathStates = {
  PA: true,
  SD: true
};

export const incomeLossLabelStates = {
  SD: 'Total Disability, Loss of Income'
};

export const coverageRSLabelStates = {
  DC: 'Roof Surfaces',
  ME: 'Roof Surfaces',
  NH: 'Roof Surfaces',
  ND: 'Roof Surfaces',
  SD: 'Roof Surfaces',
  VT: 'Roof Surfaces'
};

export const coverageRSDescriptionStates = {
  DC: 'Coverage for roof surfaces up to repair/replacement cost',
  ME: 'Coverage for roof surfaces up to repair/replacement cost. If you do not purchase this, roof claims will be handled with a payment schedule.',
  NH: 'Coverage for roof surfaces up to repair/replacement cost',
  ND: 'Coverage for roof surfaces up to repair/replacement cost',
  SD: 'Coverage for roof surfaces up to repair/replacement cost. If you do not purchase this, roof claims will be handled with a payment schedule.',
  VT: 'Coverage for roof surfaces up to repair/replacement cost'
};

// policy-level income loss here only:
export const incomeLossStates = {
  DC: true,
  PA: true,
  SD: true,
  VA: true
};

export const UMPDMustBePDOrLessStates = ['GA', 'MS'];
export const policyLevelAndCopyToCarUMPDStates = ['GA', 'IN', 'MD', 'MS', 'SC', 'TN', 'VA', 'VT', 'WV'];

export const priorCoverageRequiredInDays = {
  AL: 183,
  AR: 183,
  AZ: 183,
  CO: 30,
  DC: 183,
  GA: 183,
  IA: 183,
  ID: 183,
  IL: 183,
  IN: 183,
  KS: 183,
  KY: 183,
  LA: 183,
  MA: 183,
  MD: 364,
  ME: 30,
  MI: 30,
  MO: 183,
  MS: 183,
  MT: 183,
  ND: 183,
  NE: 183,
  NH: 30,
  NM: 183,
  OH: 183,
  OR: 183,
  OK: 183,
  PA: 183,
  SC: 183,
  SD: 183,
  TN: 183,
  TX: 30,
  UT: 183,
  VT: 183,
  VA: 183,
  WI: 183,
  WV: 183,
  WY: 183
};

export const umpdDeductibleByState = {
  AR: 200,
  DC: 200,
  GA: 250,
  ID: 250,
  IL: 250,
  LA: 250,
  MD: 250,
  MS: 200,
  NM: 250,
  OH: 250,
  OR: 200,
  SC: 200,
  TN: 200,
  UT: 250,
  VT: 150,
  WY: 200
};

export const noChargeInLastXDaysState = { AR: 60, MS: 60, SD: 60 };

export const changeRejectionLangToNeedToTalkStates = { NH: true, FL: true };
export const changeRejectionLangToNeedToTalkPhoneNumberStates = { FL: '8442276528' };

export const communityDriveAvailableStates = {
  AL: { showForBix: true, showForNonBix: true },
  AR: { showForBix: true, showForNonBix: true },
  AZ: { showForBix: true, showForNonBix: false },
  CO: { showForBix: true, showForNonBix: true },
  DC: { showForBix: true, showForNonBix: true },
  GA: { showForBix: true, showForNonBix: true },
  IA: { showForBix: true, showForNonBix: true },
  ID: { showForBix: true, showForNonBix: true },
  IL: { showForBix: true, showForNonBix: false },
  IN: { showForBix: true, showForNonBix: true },
  KS: { showForBix: true, showForNonBix: true },
  KY: { showForBix: true, showForNonBix: true },
  LA: { showForBix: true, showForNonBix: true },
  MA: { showForBix: true, showForNonBix: true },
  MD: { showForBix: true, showForNonBix: true },
  ME: { showForBix: true, showForNonBix: true },
  MI: { showForBix: true, showForNonBix: true },
  MO: { showForBix: true, showForNonBix: false },
  MS: { showForBix: true, showForNonBix: true },
  MT: { showForBix: true, showForNonBix: true },
  ND: { showForBix: true, showForNonBix: true },
  NE: { showForBix: true, showForNonBix: true },
  NH: { showForBix: true, showForNonBix: true },
  NM: { showForBix: true, showForNonBix: true },
  OH: { showForBix: true, showForNonBix: false },
  OK: { showForBix: true, showForNonBix: true },
  OR: { showForBix: true, showForNonBix: true },
  PA: { showForBix: true, showForNonBix: true },
  SC: { showForBix: true, showForNonBix: true },
  SD: { showForBix: true, showForNonBix: true },
  TN: { showForBix: true, showForNonBix: true },
  TX: { showForBix: true, showForNonBix: false },
  UT: { showForBix: true, showForNonBix: true },
  VA: { showForBix: false, showForNonBix: false },
  VT: { showForBix: true, showForNonBix: true },
  WI: { showForBix: true, showForNonBix: true },
  WV: { showForBix: true, showForNonBix: true },
  WY: { showForBix: true, showForNonBix: true }
};

export const errorCodes = {
  CHAT: 4000,
  EMAIL: 4001,
  POWER: 4002,
  NOTLIVE: 4003,
  NOT_ENOUGH_INFO: 4004,
  INVALIDINPUT: 4005,
  NEEDPRIORADDRESS: 5001,
  NEEDDATEOFBIRTH: 5002,
  NEEDCV: 5003,
  NEED360: 5004,
  NEEDCAR: 5005,
  INVALIDCAR: 5006,
  INVALIDQUOTE: 5007,
  INVALIDSTATE: 5008,
  WONTWRITECAR: 5009,
  INVALIDVIN: 5010,
  MORATORIUM: 5011,
  NEEDDRIVERSLICENSE: 5012,
  NOPOBOXADDRESS: 5013,
  MIN_PREMIUM: 5014,
  PRIMARYUDRNODRIVERSLICENSE: 5015,
  NEEDPERSONINFO: 5016,
  RESTRICTEDSTATE: 5017,
  SETFEES: 5018,
  SUSPICIOUSQUOTE: 5019,
  BLANKQUOTE: 5020,
  QUOTELIMITEXCEEDED: 5021,
  SECOND_TIME_PRIOR_CANCEL_NON_PAY: 5022,
  MISSING_LAT_LNG: 5023,
  INVALID_ZIP_CODE: 5024,
  NEEDAPTUNIT: 5025,
  CANNOTQUOTEONLINE: 5026,
  NBZIP: 5027,
  NOT_ALLOWED_TO_QUOTE_IN_STATE: 5028,
  RESTRICTED_STATE_FOR_DIRECT: 5029,
  OLD_OFFER: 5030,
  RIDESHARING_RESTRICTION: 5031,
  UNKNOWN: 0
};
export const rejectCodes = {
  // all codes from 10000 - 49999 are FCRA
  VERISK_GENERIC: 10000,
  VERISK_PROPERTY_CLAIMS_TOTAL: 10009,
  VERISK_PROPERTY_CLAIMS_SAME_PERIL: 10010,
  VERISK_PROPERTY_CLAIMS_OLDER_HOME: 10011,
  VERISK_PROPERTY_CLAIMS_BITE: 10012,
  VERISK_AUTO_CLAIMS_SUS: 10013,
  VERISK_AUTO_CLAIMS_DIS: 10014,
  VERISK_AUTO_CLAIMS_POLICY: 10015,
  VERISK_AUTO_CLAIMS_DRIVER: 10016,
  VERISK_PIP_CLAIMS: 10017,
  VERISK_AUTO_CLAIMS_FEL: 10018,
  VERISK_RENTERS_HOME_CLAIMS_THREE_YEARS: 10019,
  VERISK_RENTERS_HOME_WATER_LOSSES: 10020,

  TU_DRIVERRISK_GENERIC: 20000,
  TU_DRIVERRISK_SUS: 20001,
  TU_DRIVERRISK_DIS: 20002,
  TU_DRIVERRISK_POLICY: 20003,
  TU_DRIVERRISK_DRIVER: 20004,
  TU_DRIVERRISK_FEL: 20005,

  // Non-FCRA rejections:
  NON_FCRA_GENERIC: 50000,
  NO_CARS: 50001,
  SQFT_HIGH: 50002,
  OCCUPANTS_HIGH: 50003,
  CAR_RATIO: 50004,
  YEARBUILT_OLD: 50005,
  VERISK_NO360: 50006,
  NO_PRIOR_AUTO_INSURANCE: 50007,
  VERISK_360_EIFS: 50008,
  VERISK_360_STILTS: 50009,
  VERISK_360_WOOD: 50010,
  VERISK_360_RCE_LOW: 50011,
  VERISK_360_RCE_HIGH: 50012,
  VERISK_PPC_10: 50013,
  VERISK_NO_PPC: 50014,
  TOO_MANY_DRIVERS: 50015,
  TOO_MANY_CARS: 50016,
  TEST_CASE: 50017,
  INELIGIBLE_COASTAL_LOCATION: 50018,
  VERISK_NO_FIRELINE: 50019,
  VERISK_FIRELINE_TOO_HIGH: 50020,
  VERISK_FIRELINE_PLUS_FPC_TOO_HIGH: 50021,
  NO_DTC: 50022,
  INELIGIBLE_DTC: 50023,
  SPP_OVER_10PCT_COVC: 50024,
  SPP_OVER_10PCT_COVA: 50025,
  SPP_TOTAL_OVER_50PCT_COVC: 50026,
  NO_HOME_WIND_HAIL_EXCLUSION: 50027,
  VERISK_PPC_TOO_HIGH: 50028,
  DID_NOT_ASK_FOR_AUTO: 50029,
  CAPE_ROOF_CONDITION: 50030,
  CAPE_YARD_DEBRIS: 50031,
  COASTAL_COUNTY: 50032,
  LESS_THAN_1YEAR_PC: 50033,
  INVALID_PRIOR_CARRIER: 50034,
  ANY_INCIDENTS_OR_VIOLATIONS: 50035,
  MORE_CARS_THAN_DRIVERS: 50036,
  BI_TOO_LOW: 50037,
  CV_NO_HIT: 50038,
  SOME_DRIVERS_EXCLUDED: 50039,
  INSURANCE_SCORE_LOW: 50040,
  SELF_REPORTED_HOME_UW_DISQUALIFIER: 50041,
  SELF_REPORTED_AUTO_UW_DISQUALIFIER: 50042,
  CONDO_RENTED_OUT_RESTRICTION: 50083,
  NO_MONOLINE_HOME: 50084,
  NO_MONOLINE_CONDO: 50085,
  NO_MONOLINE_AUTO: 50086,
  PRIMARY_AGE_RESTRICTION: 50087,
  SECONDARY_HOME: 50088,
  HOME_MUST_BE_CLEAN: 50089,

  // Florida Rejections

  // Home
  // If any is added, remember to add it also in "floridaHomeSoftRejectCodes" or "floridaHomeHardRejectCodes"
  // The ones without FL_ prefix are country wide
  FL_WATER_HEATER_AGE_GREATER_THAN_15_YEARS: 50043,
  FL_COV_A_LESS_THAN_100000: 50044,
  FL_TRAMPOLINES_PRESENT: 50045,
  FL_GREATER_THAN_5_ACRES_OR_PC_9_OR_10: 50046,
  FL_MOBILE_PREFABRICATED_HOMES_LOG_OR_UNCONVENTIONAL_CONSTRUCTION: 50047,
  FL_HOMES_WITH_INELIGIBLE_DOG_BREED_OR_BITE_AGGRESSION_HISTORY: 50048,
  FL_PRIOR_SINKHOLE_ACTIVITY_OR_ADJACENT_TO_KNOWN_SINKHOLES: 50049,
  FL_SADDLE_ANIMALS_FARM_OR_NON_DOMESTICATED_ANIMALS: 50050,
  FL_OPEN_FOUNDATIONS: 50051,
  FL_PROPERTY_IS_IN_FORECLOSURE: 50052,
  FL_POLYBUTYLENE_PLUMBING: 50053,
  FL_SKATEBOARD_RAMP_DIVING_BOARD_BIKE_RAMP_POOL_SLIDE: 50054,
  FL_PROPERTY_OWNED_BY_CORP_LLC_LP_LLP_ESTATE_OR_PARTNERSHIP: 50055,
  FL_EXCLUDE_CONTENT_COVERAGE: 50056,
  FL_RUN_DAY_CARE_OUT_OF_MY_HOME: 50057,
  FL_SEASONAL_OR_SECONDARY_HOME: 50058,
  FL_MORE_THAN_2_MORTGAGES: 50059,
  FL_COMMERCIAL_OCCUPATION: 50060,
  FL_HOME_GREATER_THAN_40_YEARS: 50061,
  FL_SHINGLE_OR_WOOD_ROOF: 50062,
  FL_INELIGIBLE_OCCUPANCY: 50063,
  FL_FLAT_ROOFS: 50064,
  FL_TILE_OR_METAL_ROOFS_GREATER_THAN_25: 50065,
  FL_PRIOR_LOSS_IN_5_YEARS: 50066,
  FL_TWO_PROPERTY_OR_LIABILITY_LOSSES: 50067,
  FL_NO_PRIOR_INSURANCE: 50068,
  FL_PRIOR_POLICY_WAS_CANCELLED_OR_NON_RENEWED: 50069,
  FL_COV_A_GREATER_THAN_950K: 50070,
  FL_REQUESTED_SINKHOLE_COVERAGE: 50071,
  FL_UNCONVENTIONAL_CONSTRUCTION_OR_DEFECTIVE_MATERIALS: 50072,
  FL_NO_HOME_WIND_HAIL_EXCLUSION: 50073,

  // Auto
  // If any is added, remember to add it also in "floridaAutoSoftRejectCodes" or "floridaAutoHardRejectCodes"
  // The ones without FL_ prefix are country wide
  FL_EXCLUDE_BI_COVERAGE: 50074,
  FL_LAPSE_GREATER_THAN_2_DAYS: 50075,
  FL_MORE_THAN_1_PIP_CLAIM_PAST_3_YEARS: 50076,
  FL_MORE_THAN_1_AT_FAULT_ACCIDENT_PAST_3_YEARS: 50077,
  FL_MORE_THAN_2_ACCIDENTS_REGARDLESS_OF_FAULT_PAST_3_YEARS: 50078,
  FL_MORE_THAN_2_INFRACTIONS_PAST_3_YEARS: 50079,
  FL_INSURANCE_SCORE_IS_NO_HIT_OR_NO_SCORE: 50080,
  FL_MVR_IS_A_NO_HIT_OR_UDR: 50081,
  FL_NEED_SR22: 50082
};

export const floridaHomeSoftRejectCodes = [
  rejectCodes.FL_WATER_HEATER_AGE_GREATER_THAN_15_YEARS,
  rejectCodes.FL_COV_A_LESS_THAN_100000,
  rejectCodes.FL_GREATER_THAN_5_ACRES_OR_PC_9_OR_10,
  rejectCodes.FL_RUN_DAY_CARE_OUT_OF_MY_HOME,
  rejectCodes.FL_HOME_GREATER_THAN_40_YEARS,
  rejectCodes.FL_SHINGLE_OR_WOOD_ROOF,
  rejectCodes.FL_FLAT_ROOFS,
  rejectCodes.FL_PRIOR_LOSS_IN_5_YEARS,
  rejectCodes.FL_NO_PRIOR_INSURANCE,
  rejectCodes.FL_PRIOR_POLICY_WAS_CANCELLED_OR_NON_RENEWED,
  rejectCodes.FL_REQUESTED_SINKHOLE_COVERAGE,
  rejectCodes.FL_COV_A_GREATER_THAN_950K,
  rejectCodes.FL_EXCLUDE_CONTENT_COVERAGE,
  rejectCodes.FL_NO_HOME_WIND_HAIL_EXCLUSION,
  rejectCodes.FL_SEASONAL_OR_SECONDARY_HOME
];

export const floridaHomeHardRejectCodes = [
  rejectCodes.FL_TRAMPOLINES_PRESENT,
  rejectCodes.FL_MOBILE_PREFABRICATED_HOMES_LOG_OR_UNCONVENTIONAL_CONSTRUCTION,
  rejectCodes.FL_HOMES_WITH_INELIGIBLE_DOG_BREED_OR_BITE_AGGRESSION_HISTORY,
  rejectCodes.FL_PRIOR_SINKHOLE_ACTIVITY_OR_ADJACENT_TO_KNOWN_SINKHOLES,
  rejectCodes.FL_SADDLE_ANIMALS_FARM_OR_NON_DOMESTICATED_ANIMALS,
  rejectCodes.FL_OPEN_FOUNDATIONS,
  rejectCodes.FL_PROPERTY_IS_IN_FORECLOSURE,
  rejectCodes.FL_SKATEBOARD_RAMP_DIVING_BOARD_BIKE_RAMP_POOL_SLIDE,
  rejectCodes.FL_PROPERTY_OWNED_BY_CORP_LLC_LP_LLP_ESTATE_OR_PARTNERSHIP,
  rejectCodes.FL_POLYBUTYLENE_PLUMBING,
  rejectCodes.FL_MORE_THAN_2_MORTGAGES,
  rejectCodes.FL_TILE_OR_METAL_ROOFS_GREATER_THAN_25,
  rejectCodes.FL_TWO_PROPERTY_OR_LIABILITY_LOSSES,
  rejectCodes.FL_INELIGIBLE_OCCUPANCY,
  rejectCodes.FL_UNCONVENTIONAL_CONSTRUCTION_OR_DEFECTIVE_MATERIALS,
  rejectCodes.FL_COMMERCIAL_OCCUPATION,
  rejectCodes.INELIGIBLE_DTC
];

export const floridaAutoSoftRejectCodes = [
  rejectCodes.FL_EXCLUDE_BI_COVERAGE,
  rejectCodes.FL_LAPSE_GREATER_THAN_2_DAYS,
  rejectCodes.FL_INSURANCE_SCORE_IS_NO_HIT_OR_NO_SCORE,
  rejectCodes.FL_MVR_IS_A_NO_HIT_OR_UDR
];

export const floridaAutoHardRejectCodes = [
  rejectCodes.FL_NEED_SR22,
  rejectCodes.FL_MORE_THAN_1_PIP_CLAIM_PAST_3_YEARS,
  rejectCodes.FL_MORE_THAN_1_AT_FAULT_ACCIDENT_PAST_3_YEARS,
  rejectCodes.FL_MORE_THAN_2_ACCIDENTS_REGARDLESS_OF_FAULT_PAST_3_YEARS,
  rejectCodes.FL_MORE_THAN_2_INFRACTIONS_PAST_3_YEARS,
  rejectCodes.INELIGIBLE_DTC,
  rejectCodes.INELIGIBLE_COASTAL_LOCATION
];

export const monolineAutoRejectCodes = [
  rejectCodes.LESS_THAN_1YEAR_PC,
  rejectCodes.CV_NO_HIT,
  rejectCodes.INVALID_PRIOR_CARRIER,
  rejectCodes.ANY_INCIDENTS_OR_VIOLATIONS,
  rejectCodes.MORE_CARS_THAN_DRIVERS,
  rejectCodes.BI_TOO_LOW,
  rejectCodes.SOME_DRIVERS_EXCLUDED,
  rejectCodes.INSURANCE_SCORE_LOW
];

// Specific set of Reject Codes that are set somewhere but need to be cleared in Staff
export const rejectCodesToBeClearedInStaff = [rejectCodes.NO_HOME_WIND_HAIL_EXCLUSION];

export const fixableRejectionCodes = [50001, 50004, 50006, 50014, 50015, 500016, 50022];

export const restrictedBLimitsStates = [];
export const restrictedCLimitsStates = ['DC', 'LA', 'ME', 'NH', 'SD', 'VT'];
export const restrictedDLimitsStates = ['DC', 'LA', 'ME', 'MS', 'ND', 'NH', 'SD', 'VT'];
export const unlimitedDLimitsStates = ['CO'];
export const UNLIMITED_D_VALUE = -9999;

export const coverageFOffered = {
  AL: true,
  AR: true,
  AZ: false,
  CO: true,
  DC: true,
  GA: true,
  IA: true,
  ID: true,
  IL: true,
  IN: true,
  KS: true,
  KY: true,
  LA: true,
  MA: true,
  MD: true,
  MI: true,
  MO: true,
  MS: true,
  MT: true,
  ND: true,
  ME: true,
  NE: true,
  NH: true,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: true,
  SD: true,
  TN: true,
  TX: true,
  UT: true,
  VA: true,
  VT: true,
  WI: true,
  WV: true,
  WY: true
};

export const coverageDNotOffered = {
  DC: true,
  ME: true,
  MS: true,
  NH: true,
  SD: true,
  VT: true
};

export const coverageCANotOffered = {
  VA: true
};
export const coverageDRNotOffered = {
  ID: true,
  MA: true,
  ME: true,
  NH: true,
  TN: true,
  SD: true,
  VT: true,
  WY: true
};
export const coverageMINotOffered = {
  VA: true
};
export const coverageSTNotOffered = {
  ME: true,
  ND: true,
  NH: true,
  VA: true,
  VT: true
};
export const coverageSPNotOffered = {
  VA: true
};
export const coverageFairRentalNotOffered = {
  VA: true
};
export const coverageYardAndGardenNotOffered = {
  DC: true,
  SD: true,
  VA: true
};
export const coverageHomeownerProtectionNotOffered = {
  DC: true,
  ND: true,
  VA: true
};

export const offerExcessUM = {
  AL: false,
  AR: true,
  AZ: true,
  CO: false,
  DC: false,
  GA: false,
  IA: true,
  ID: false,
  IL: false,
  IN: true,
  KS: false,
  KY: false,
  LA: true,
  MA: true,
  MD: true,
  ME: false,
  MI: false,
  MO: false,
  MS: false,
  MT: true,
  ND: false,
  NE: true,
  NH: true,
  NM: false,
  OH: true,
  OK: true,
  OR: true,
  PA: false,
  SC: false,
  SD: false,
  TN: false,
  TX: false,
  UT: false,
  VA: false,
  VT: true,
  WI: true,
  WV: true,
  WY: false
};

export const rateCappingStates = {
  AR: {
    cap: 1.2,
    endDateAsInt: 29990701
  },
  KS: {
    cap: 1.3,
    endDateAsInt: 29990701
  },
  PA: {
    cap: 1.3,
    endDateAsInt: 20231110
  }
};

export const firelineCutoffsByState = {
  AZ: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  CO: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  ID: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  MT: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  NM: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  NV: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  OK: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  OR: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  TX: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  UT: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  WA: {
    firelineAlone: 4,
    firelineWithPPC: 4
  },
  WY: {
    firelineAlone: 4,
    firelineWithPPC: 4
  }
};

export const buildingMaterialsTheftCoverageByState = {
  DC: 10000,
  LA: 5000,
  ME: 5000,
  MS: 5000,
  ND: 5000,
  NH: 5000,
  SD: 5000,
  WV: 5000
};

export const mineSubsidenceMaxStates = {
  KY: 300000,
  WV: 300000
};

export const mineSubsidenceStates = {
  IL: {
    deductible: 'AOP'
  },
  IN: {
    deductible: '2%'
  },
  KY: {
    deductible: '250'
  },
  OH: {
    deductible: '2%'
  },
  WV: {
    deductible: '250'
  }
};

export const mineSubsidenceDefaulted = {
  IL: {
    BOND: true,
    BUREAU: true,
    CHRISTIAN: true,
    CLINTON: true,
    DOUGLAS: true,
    FRANKLIN: true,
    FULTON: true,
    GALLATIN: true,
    GRUNDY: true,
    ISLAND: true,
    JACKSON: true,
    JEFFERSON: true,
    KNOX: true,
    LASALLE: true,
    LOGAN: true,
    MACOUPIN: true,
    MADISON: true,
    MARION: true,
    MARSHALL: true,
    MCDONOUGH: true,
    MENARD: true,
    MERCER: true,
    MONTGOMERY: true,
    PEORIA: true,
    PERRY: true,
    PUTNAM: true,
    RANDOLPH: true,
    ROCK: true,
    'ROCK ISLAND': true,
    'SAINT CLAIR': true,
    SALINE: true,
    SANGAMON: true,
    'ST CLAIR': true,
    'ST. CLAIR': true,
    TAZEWELL: true,
    VERMILLION: true,
    WASHINGTON: true,
    WILLIAMSON: true
  }
};

export const mineSubsidenceOptional = {
  IL: {
    all: true
  },
  IN: {
    CLAY: true,
    CRAWFORD: true,
    DAVIES: true,
    DAVIESS: true,
    DUBOIS: true,
    FOUNTAIN: true,
    GIBSON: true,
    GREENE: true,
    KNOX: true,
    LAWRENCE: true,
    MARTIN: true,
    MONROE: true,
    MONTGOMERY: true,
    ORANGE: true,
    OWEN: true,
    PARKE: true,
    PERRY: true,
    PIKE: true,
    POSEY: true,
    PUTNAM: true,
    SPENCER: true,
    SULLIVAN: true,
    VANDERBURG: true,
    VANDERBURGH: true,
    VERMILION: true,
    VERMILLION: true,
    VIGO: true,
    WARREN: true,
    WARRICK: true
  },
  KY: {
    BELL: true,
    BOYD: true,
    BREATHITT: true,
    BUTLER: true,
    CARTER: true,
    CHRISTIAN: true,
    CLAY: true,
    DAVIESS: true,
    EDMONSON: true,
    ELLIOT: true,
    FLOYD: true,
    GREENUP: true,
    HANCOCK: true,
    HARLAN: true,
    HENDERSON: true,
    HOPKINS: true,
    JACKSON: true,
    JOHNSON: true,
    KNOTT: true,
    KNOX: true,
    LAUREL: true,
    LAWRENCE: true,
    LEE: true,
    LETCHER: true,
    LESLIE: true,
    MARTIN: true,
    MCCREARY: true,
    MCLEAN: true,
    MORGAN: true,
    MUHLENBERG: true,
    OHIO: true,
    OWSLEY: true,
    PERRY: true,
    UNION: true,
    WEBSTER: true,
    WHITLEY: true,
    WOLFE: true
  },
  OH: {
    DELAWARE: true,
    ERIE: true,
    GEAUGA: true,
    LAKE: true,
    LICKING: true,
    MEDINA: true,
    OTTAWA: true,
    PORTAGE: true,
    PREBLE: true,
    SUMMIT: true,
    WAYNE: true
  },
  WV: {
    BERKELEY: true,
    CABELL: true,
    CALHOUN: true,
    HAMPSHIRE: true,
    HARDY: true,
    JACKSON: true,
    JEFFERSON: true,
    MONROE: true,
    MORGAN: true,
    PENDLETON: true,
    PLEASANTS: true,
    RITCHIE: true,
    ROANE: true,
    WIRT: true,
    WOOD: true
  }
};

export const mineSubsidenceRequired = {
  KY: {
    // stub placeholder to make this clear
    NONE: true
  },
  IN: {
    // stub placeholder to make this clear
    NONE: true
  },
  OH: {
    ATHENS: true,
    BELMONT: true,
    CARROLL: true,
    COLUMBIANA: true,
    COSHOCTON: true,
    GALLIA: true,
    GUERNSEY: true,
    HARRISON: true,
    HOCKING: true,
    HOLMES: true,
    JACKSON: true,
    JEFFERSON: true,
    LAWRENCE: true,
    MAHONING: true,
    MEIGS: true,
    MONROE: true,
    MORGAN: true,
    MUSKINGUM: true,
    NOBLE: true,
    PERRY: true,
    SCIOTO: true,
    STARK: true,
    TRUMBULL: true,
    TUSCARAWAS: true,
    VINTON: true,
    WASHINGTON: true
  },
  WV: {
    BARBOUR: true,
    BOONE: true,
    BRAXTON: true,
    BROOKE: true,
    CLAY: true,
    DODDRIDGE: true,
    FAYETTE: true,
    GILMER: true,
    GRANT: true,
    GREENBRIER: true,
    HANCOCK: true,
    HARRISON: true,
    KANAWHA: true,
    LEWIS: true,
    LINCOLN: true,
    LOGAN: true,
    MCDOWELL: true,
    MARION: true,
    MARSHALL: true,
    MASON: true,
    MERCER: true,
    MINERAL: true,
    MINGO: true,
    MONONGALIA: true,
    NICHOLAS: true,
    OHIO: true,
    POCAHONTAS: true,
    PRESTON: true,
    PUTNAM: true,
    RALEIGH: true,
    RANDOLPH: true,
    SUMMERS: true,
    TAYLOR: true,
    TUCKER: true,
    TYLER: true,
    UPSHUR: true,
    WAYNE: true,
    WEBSTER: true,
    WETZEL: true,
    WYOMING: true
  }
};

export const useIneligibleCoastalCitiesForAutoEligibilityStates = {
  LA: true
};

export const ineligibleCoastalCities = {
  LA: {
    ACADIA: { all: true },
    ALLEN: { all: true },
    ASCENSION: { all: true },
    ASSUMPTION: { all: true },
    BEAUREGARD: { all: true },
    CALCASIEU: { all: true },
    CAMERON: { all: true },
    'EAST BATON ROUGE': { all: true },
    'EAST FELICIANA': { all: true },
    EVANGELINE: { all: true },
    IBERIA: { all: true },
    IBERVILLE: { all: true },
    'JEFFERSON DAVIS': { all: true },
    JEFFERSON: { all: true },
    LAFAYETTE: { all: true },
    LAFOURCHE: { all: true },
    ORLEANS: { all: true },
    PLAQUEMINES: { all: true },
    'POINTE COUPEE': { all: true },
    'ST. BERNARD': { all: true },
    'ST. CHARLES': { all: true },
    'ST. HELENA': { all: true },
    'ST. JAMES': { all: true },
    'ST. JOHN THE BAPTIST': { all: true },
    'ST. LANDRY': { all: true },
    'ST. MARTIN': { all: true },
    'ST. MARY': { all: true },
    'ST. TAMMANY': { all: true },
    TANGIPAHOA: { all: true },
    TERREBONNE: { all: true },
    VERMILION: { all: true },
    WASHINGTON: { all: true },
    'WEST BATON ROUGE': { all: true }
  },
  MA: {
    BARNSTABLE: { all: true },
    DUKES: { all: true },
    NANTUCKET: { all: true }
  },
  MS: {
    AMITE: { all: true },
    FORREST: { all: true },
    GEORGE: { all: true },
    GREENE: { all: true },
    HANCOCK: { all: true },
    HARRISON: { all: true },
    JACKSON: { all: true },
    LAMAR: { all: true },
    MARION: { all: true },
    'PEARL RIVER': { all: true },
    PERRY: { all: true },
    PIKE: { all: true },
    STONE: { all: true },
    WALTHALL: { all: true },
    WILKINSON: { all: true }
  },
  SC: {
    BEAUFORT: { all: true },
    CHARLESTON: { all: true },
    COLLETON: { all: true },
    GEORGETOWN: { all: true },
    HORRY: { all: true },
    JASPER: { all: true }
  },
  TX: {
    ARANSAS: { all: true },
    BRAZORIA: { all: true },
    CALHOUN: { all: true },
    CAMERON: { all: true },
    CHAMBERS: { all: true },
    GALVESTON: { all: true },
    HARRIS: {
      'LA PORTE': true,
      LAPORTE: true,
      "MORGAN'S POINT": true,
      'MORGANS POINT': true,
      MORGANSPOINT: true,
      PASADENA: true,
      SEABROOK: true,
      SHOREACRES: true
    },
    JEFFERSON: { all: true },
    KENEDY: { all: true },
    KLEBERG: { all: true },
    MATAGORDA: { all: true },
    NUECES: { all: true },
    REFUGIO: { all: true },
    'SAN PATRICIO': { all: true },
    WILLACY: { all: true }
  },
  FL: {
    'MIAMI-DADE': { all: true },
    BROWARD: { all: true },
    'PALM BEACH': { all: true }
  }
};

export const monolineAutoRejectInCoastalCountiesStates = {
  SC: true
};

export const coastalTerritoryCounties = {
  SC: {
    BEAUFORT: true,
    BERKELEY: true,
    CHARLESTON: true,
    COLLETON: true,
    DORCHESTER: true,
    GEORGETOWN: true,
    HORRY: true,
    JASPER: true
  }
};

export const coastalCounties1PctWH = {
  GA: {
    BRYAN: true,
    CAMDEN: true,
    CHATHAM: true,
    GLYNN: true,
    LIBERTY: true,
    MCINTOSH: true
  }
};

// county elements are cities, or "all" for all other cities
// array elements are distance to coast:
// <2mi, 2-3mi, 3-5mi, 5-10mi, 10+mi
export const coastalCountiesMinimumHurricaneDeductible = {
  LA: {
    AVOYELLES: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    BIENVILLE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    BOSSIER: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CADDO: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CALDWELL: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CATAHOULA: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CLAIBORNE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    CONCORDIA: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'DE SOTO': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'EAST CARROLL': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    FRANKLIN: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    GRANT: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    JACKSON: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    LASALLE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    LINCOLN: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    LIVINGSTON: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    MADISON: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    MOREHOUSE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    NATCHITOCHES: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    OUACHITA: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    RAPIDES: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'RED RIVER': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    RICHLAND: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    SABINE: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    TENSAS: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    UNION: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    VERNON: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    WEBSTER: { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'WEST CARROLL': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    'WEST FELICIANA': { all: [0.02, 0.02, 0.02, 0.02, 0.02] },
    WINN: { all: [0.02, 0.02, 0.02, 0.02, 0.02] }
  },
  MA: {
    BARNSTABLE: { all: [0.05, 0.05, 0.05, 0.05, 0.05] },
    DUKES: { all: [0.05, 0.05, 0.05, 0.05, 0.05] },
    NANTUCKET: { all: [0.05, 0.05, 0.05, 0.05, 0.05] },
    BRISTOL: { all: [0.05, 0.05, 0.02, 1000, 1000] },
    PLYMOUTH: { all: [0.05, 0.05, 0.02, 1000, 1000] },
    NORFOLK: { COHASSET: [0.05, 0.05, 0.02, 1000, 1000], all: [0.02, 0.01, 0.01, 1000, 1000] },
    ESSEX: { all: [0.02, 1000, 1000, 1000, 1000] },
    SUFFOLK: { all: [0.02, 1000, 1000, 1000, 1000] }
  }
};

export const dtcToArrayIndexForMinimumHurricaneDeductible = {
  '01': 0,
  '02': 0,
  '03': 0,
  '04': 0,
  '05': 0,
  '06': 0,
  '07': 0,
  '08': 1,
  '09': 2,
  '10': 2,
  '11': 3,
  '12': 4,
  '13': 4,
  '14': 4,
  '15': 4,
  '16': 4,
  '': 4
};

export const coastalCounties = {
  SC: {
    ALLENDALE: true,
    BAMBERG: true,
    BARNWELL: true,
    BEAUFORT: true,
    BERKELEY: true,
    CALHOUN: true,
    CHARLESTON: true,
    CLARENDON: true,
    COLLETON: true,
    DARLINGTON: true,
    DILLION: true,
    DILLON: true,
    DORCHESTER: true,
    FLORENCE: true,
    GEORGETOWN: true,
    HAMPTON: true,
    HORRY: true,
    JASPER: true,
    LEE: true,
    MARION: true,
    MARLBORO: true,
    ORANGEBURG: true,
    SUMTER: true,
    WILLIAMSBURG: true
  },
  LA: {
    ACADIA: true,
    ALLEN: true,
    ASCENSION: true,
    ASSUMPTION: true,
    BEAUREGARD: true,
    CALCASIEU: true,
    CAMERON: true,
    'EAST BATON ROUGE': true,
    'EAST FELICIANA': true,
    EVANGELINE: true,
    IBERIA: true,
    IBERVILLE: true,
    'JEFFERSON DAVIS': true,
    JEFFERSON: true,
    LAFAYETTE: true,
    LAFOURCHE: true,
    ORLEANS: true,
    PLAQUEMINES: true,
    'POINTE COUPEE': true,
    'ST. BERNARD': true,
    'ST. CHARLES': true,
    'ST. HELENA': true,
    'ST. JAMES': true,
    'ST. JOHN THE BAPTIST': true,
    'ST. LANDRY': true,
    'ST. MARTIN': true,
    'ST. MARY': true,
    'ST. TAMMANY': true,
    TANGIPAHOA: true,
    TERREBONNE: true,
    VERMILION: true,
    WASHINGTON: true,
    'WEST BATON ROUGE': true
  },
  TX: {
    HARDIN: true,
    JEFFERSON: true,
    ORANGE: true,
    AUSTIN: true,
    BRAZORIA: true,
    CHAMBERS: true,
    COLORADO: true,
    'FORT BEND': true,
    GALVESTON: true,
    HARRIS: true,
    LIBERTY: true,
    MATAGORDA: true,
    MONTGOMERY: true,
    WALKER: true,
    WALLER: true,
    WHARTON: true,
    CALHOUN: true,
    DEWITT: true,
    GOLIAD: true,
    GONZALES: true,
    JACKSON: true,
    LAVACA: true,
    VICTORIA: true,
    ARANSAS: true,
    BEE: true,
    BROOKS: true,
    DUVAL: true,
    'JIM WELLS': true,
    KENEDY: true,
    KLEBURG: true,
    KLEBERG: true,
    'LIVE OAK': true,
    NUECES: true,
    REFUGIO: true,
    'SAN PATRICIO': true,
    CAMERON: true,
    HIDALGO: true,
    WILLACY: true
  }
};

export const mandatoryWindHailDeductibleValueMinimumStates = {
  MD: 2500
};

export const mandatoryWindHailDeductibleByZip = {};

export const mandatoryWindHailDeductibleByCounty = {
  LA: {
    AVOYELLES: 0.01,
    BIENVILLE: 0.01,
    BOSSIER: 0.01,
    CADDO: 0.01,
    CALDWELL: 0.01,
    CATAHOULA: 0.01,
    CLAIBORNE: 0.01,
    CONCORDIA: 0.01,
    'DE SOTO': 0.01,
    'EAST CARROLL': 0.01,
    FRANKLIN: 0.01,
    GRANT: 0.01,
    JACKSON: 0.01,
    LASallE: 0.01,
    LINCOLN: 0.01,
    LIVINGSTON: 0.01,
    MADISON: 0.01,
    MOREHOUSE: 0.01,
    NATCHITOCHES: 0.01,
    OUACHITA: 0.01,
    RAPIDES: 0.01,
    'RED RIVER': 0.01,
    RICHLAND: 0.01,
    SABINE: 0.01,
    TENSAS: 0.01,
    UNION: 0.01,
    VERNON: 0.01,
    WEBSTER: 0.01,
    'WEST CARROLL': 0.01,
    'WEST FELICIANA': 0.01,
    WINN: 0.01
  },
  MD: {
    ALLEGANY: 2500,
    GARRETT: 2500,
    WASHINGTON: 2500,
    FREDERICK: 2500,
    CARROLL: 2500,
    HOWARD: 2500,
    MONTGOMERY: 2500,
    CHARLES: 0.01,
    'PRINCE GEORGES': 0.01,
    "PRINCE GEORGE'S": 0.01,
    'ANNE ARUNDEL': 0.01,
    BALTIMORE: 0.01,
    'BALTIMORE COUNTY': 0.01,
    'BALTIMORE CITY': 0.01,
    HARFORD: 0.01,
    CECIL: 0.01,
    KENT: 0.01,
    'QUEEN ANNES': 0.01,
    "QUEEN ANNE'S": 0.01,
    CAROLINE: 0.01,
    CALVERT: 0.02,
    'ST MARYS': 0.02,
    'ST. MARYS': 0.02,
    'SAINT MARYS': 0.02,
    "ST. MARY'S": 0.02,
    "ST MARY'S": 0.02,
    "SAINT MARY'S": 0.02,
    TALBOT: 0.02,
    DORCHESTER: 0.02,
    WICOMICO: 0.02,
    SOMERSET: 0.02,
    WORCESTER: 0.02
  },
  MO: {
    JEFFERSON: 0.01,
    'St. Louis': 0.01,
    'ST. LOUIS CITY': 0.01,
    'ST. LOUIS COUNTY': 0.01
  },
  MS: {
    ADAMS: 0.02,
    COVINGTON: 0.02,
    FRANKLIN: 0.02,
    GEORGE: 0.02,
    HANCOCK: 0.02,
    HARRISON: 0.02,
    JACKSON: 0.02,
    'JEFFERSON DAVIS': 0.02,
    JONES: 0.02,
    LAWRENCE: 0.02,
    LINCOLN: 0.02,
    'PEARL RIVER': 0.02,
    STONE: 0.02,
    WAYNE: 0.02
  }
};

export const aopDeductibleIsAlwaysPercent = {
  TN: false,
  WY: false
};

export const windHailDeductibleCanVaryByCounty = {
  GA: {
    BRYAN: true,
    CAMDEN: true,
    CHATHAM: true,
    GLYNN: true,
    LIBERTY: true,
    MCINTOSH: true
  }
};

export const noWindHailDeductibleStates = {
  DC: true,
  ND: true,
  SD: true,
  NH: true,
  TN: true,
  VT: true,
  WY: true
};

export const rentersPolicyAvailable = {
  AL: true,
  AR: true,
  AZ: true,
  CO: true,
  DC: false,
  FL: false,
  GA: true,
  IA: true,
  ID: true,
  IL: true,
  IN: true,
  KS: false,
  KY: false,
  LA: false,
  MA: false,
  MD: false,
  ME: false,
  MI: false,
  MO: true,
  MS: false,
  MT: false,
  ND: true,
  NE: true,
  NH: false,
  NM: true,
  OH: true,
  OK: true,
  OR: true,
  PA: true,
  SC: false,
  SD: false,
  TN: false,
  TX: false,
  UT: true,
  VA: true,
  VT: false,
  WI: true,
  WV: true,
  WY: false
};

export const standaloneRentersStates = {
  AZ: true,
  // only for testing, need to diable later
  ND: true,
  NM: true,
  PA: true,
  CO: true
};

export const condoStates = {
  AZ: true,
  MI: true,
  MO: true,
  OH: true,
  PA: true,
  WI: true
};

export const pipCheckStates = {
  KY: true
};

export const localPremiumTaxStates = {
  KY: true
};

export const statePremiumTaxStates = {
  KY: true
};

export const noShowRoofSurfacesExtendedUnderXYearsStates = {
  AR: 10,
  GA: 15
};

export const coverageRSForcedAtXYearsStates = {
  AL: 15,
  AR: 15,
  AZ: 15,
  CO: 15,
  GA: 15,
  IA: 15,
  ID: 15,
  IL: 15,
  IN: 15,
  KS: 15,
  LA: 15,
  MI: 15,
  MO: 10,
  MS: 15,
  MT: 15,
  NE: 15,
  OH: 15,
  OK: 15,
  OR: 15,
  PA: 15,
  SC: 15,
  SD: 15,
  TN: 15,
  TX: 15,
  UT: 15,
  VT: 15,
  WI: 15,
  WV: 15,
  WY: 15
};

export const cleanEligibilityInHomeownersStates = {
  AL: 523,
  AZ: 523,
  AR: 523,
  GA: 523,
  IL: 523,
  ID: 523,
  IN: 523,
  IA: 523,
  KS: 402,
  LA: 523,
  MI: 523,
  MS: 523,
  MT: 523,
  ND: 523,
  NE: 523,
  NM: 523,
  TN: 523,
  OH: 523,
  OK: 523,
  OR: 523,
  PA: 523,
  SC: 402,
  SD: 523,
  TX: 523,
  UT: 523,
  VA: 523,
  VT: 523,
  WV: 523,
  WI: 523,
  WY: 523
};

export const selfInspectionStates = { IN: true };

export const minimumDrivingAge = {
  AL: 15,
  AK: 14,
  AZ: 15,
  AR: 14,
  CA: 15,
  CO: 15,
  CT: 16,
  DE: 16,
  DC: 16,
  FL: 15,
  GA: 15,
  HI: 14,
  ID: 14,
  IL: 15,
  IN: 15,
  IA: 14,
  KS: 14,
  KY: 16,
  LA: 15,
  ME: 15,
  MD: 15,
  MA: 16,
  MI: 14,
  MN: 15,
  MS: 15,
  MO: 15,
  MT: 14,
  NE: 15,
  NV: 15,
  NH: 15,
  NJ: 16,
  NM: 16,
  NY: 16,
  NC: 15,
  ND: 14,
  OH: 15,
  OK: 15,
  OR: 15,
  PA: 16,
  RI: 16,
  SC: 15,
  SD: 14,
  TN: 15,
  TX: 15,
  UT: 15,
  VT: 15,
  VA: 15,
  WA: 15,
  WV: 15,
  WI: 15,
  WY: 15
};

export const minimumDrivingAgefor5YearDiscount = {
  AL: 21,
  AK: 21,
  AZ: 21,
  AR: 21,
  CA: 21,
  CO: 21,
  CT: 21.33,
  DE: 21.5,
  DC: 21.5,
  FL: 21,
  GA: 21,
  HI: 21,
  ID: 20,
  IL: 21,
  IN: 21.25,
  IA: 21,
  KS: 21,
  KY: 21.5,
  LA: 21,
  ME: 21,
  MD: 21.5,
  MA: 21.5,
  MI: 21,
  MN: 21,
  MS: 21,
  MO: 21,
  MT: 20,
  NE: 21,
  NV: 21,
  NH: 21,
  NJ: 22,
  NM: 20.5,
  NY: 21.5,
  NC: 21,
  ND: 20,
  OH: 21,
  OK: 21,
  OR: 21,
  PA: 21.5,
  RI: 21.5,
  SC: 20.5,
  SD: 19.5,
  TN: 21,
  TX: 21,
  UT: 21,
  VT: 21,
  VA: 21,
  WA: 21,
  WV: 21,
  WI: 21,
  WY: 21
};

export const vehicleEquipmentStatusCode = {
  OPTIONAL: 'O',
  STANDARD: 'S',
  NO: 'N'
};

export const HomeSecurityPartnerCustomerType = {
  CURRENT: 'C',
  NEW_BRANCH: 'B'
};

export const mortgageHoldersAffinities = [
  {
    name: 'QUICKEN LOANS INC ISAOA',
    affinity: 'QL1'
  }
];

export const mortgageClauses = {
  RM1: {
    correctName: 'T2 FINANCIAL LLC DBA/ REVOLUTION MORTGAGE ISAOA/ATIMA',
    address: '480 Olde Worthington Road',
    address2: 'Ste 300',
    city: 'Westerville',
    state: 'OH',
    zip: '43082'
  }
};

export const newCustomersDefaultDevices = {
  ADT: {
    moistureDevices: 2,
    theftPreventionDevices: 2,
    motionDetectingDevices: 1,
    smokeDetectingDevices: 2,
    autoWaterShutoffDevices: false
  },
  SimpliSafe: {
    moistureDevices: 1,
    theftPreventionDevices: 1,
    motionDetectingDevices: 1,
    smokeDetectingDevices: 1,
    autoWaterShutoffDevices: false
  }
};

export const connectedHomePromoDetails = {
  SimpliSafe: {
    promoItems: [
      '7-piece security system guards against break-ins and fires--plus includes 24/7 video monitoring. A $403 value, yours FREE.',
      'Monitored by professionals 24/7 for only $17.99/month - with NO long-term contract. Your first month is FREE.',
      'Ranked the best overall home security system of 2021 by U.S. News and World Report'
    ],
    privacyPolicyUrl: 'https://simplisafe.com/privacy'
  },
  ADT: {
    promoItems: [
      'Get ADTs best offer PLUS receive 2 flood detectors FREE (Requires 36 month monitoring contract. Early termination and installation fees apply.)',
      'ADT is the # 1 smart home security provider in the U.S. (Source: Strategy Analytics, 2020)',
      '$0 Down*** 0% Interest 100% Real Protection. Now you can get the security you need with the smart home devices you want with $0 down and flexible, easy, low monthly payments on professionally installed equipment. (***Requires 0% APR installment agreement with equal monthly payments based on term/equipment selected. Well-qualified credit. Requires monthly monitoring contract for finance term selected. Early term fees apply.)'
    ],
    privacyPolicyUrl: 'https://www.adt.com/about-adt/legal/privacy-policy'
  },
  Kangaroo: {
    privacyPolicyUrl: 'https://info.heykangaroo.com/legal#legal-privacy-policy'
  }
};

export const HighestEducation = {
  H: 'High School or less',
  S: 'Some College',
  A: 'Associates Degree',
  B: 'Bachelors Degree',
  M: 'Masters Degree',
  D: 'Doctoral Degree'
};

export const ratingSeriousness = {
  AAF: 4,
  AFM: 0,
  AFN: 2,
  ANC: 0,
  ANO: 0,
  ASW: 0,
  BOT: 1,
  CCW: 0,
  CML: 2,
  CMP: 0,
  CMU: 0,
  CRD: 4,
  DEV: 2,
  DEQ: 2,
  DR: 8,
  DWI: 8,
  FAR: 2,
  FDL: 2,
  FEL: 8,
  FFR: 8,
  FLE: 8,
  FRA: 2,
  FTC: 2,
  FTY: 2,
  HOM: 8,
  IBK: 2,
  IP: 2,
  IT: 2,
  LDL: 4,
  LIC: 2,
  LTS: 4,
  MAJ: 4,
  MMV: 2,
  NAF: 0,
  NFX: 0,
  PUA: 0,
  PUN: 0,
  REF: 1,
  RKD: 8,
  SAF: 2,
  SCH: 2,
  SLV: 4,
  SPD: 2,
  SUS: 10,
  TMP: 0,
  UDR: 4,
  WSR: 2
};

export const monarchPoints = {
  AAF: 3,
  CMP: 2,
  NAF: 1
};

export const homeClaimPoints = {
  D: 4,
  G: 3,
  E: 2,
  F: 1
};

export const connectedHomeProvidersAllowingVerification = ['ADT'];

export const violationCodeToDescription = {
  AAF: 'At-Fault Accident',
  ANC: 'Waived Claim - Closed',
  ANO: 'Waived Claim - Open',
  ASW: 'Accident Surcharge Waived',
  BOT: 'Comprehensive Claim',
  CCW: 'Comprehensive Claim - Weather',
  CMU: 'Comprehensive Claim Less Than or Equal to $1,000',
  CRD: 'Careless or Improper Driving',
  DEQ: 'Defective Equipment',
  DEV: 'Traffic Device/Sign Violation',
  DR: 'Driving Under The Influence of Alcohol/Drugs',
  FAR: 'False Reporting or Perjury',
  FDL: `Foreign Driver's License`,
  FEL: 'Auto Theft or Felony Involving a Motor Vehicle',
  FLE: 'Fleeing or Eluding the Police',
  FRA: 'Failure to Report an Accident',
  FTC: 'Following Too Close',
  FTY: 'Improper or Illegal Passing',
  IT: `Operating Without Owner's Consent`,
  LIC: 'License or Credentials Violation',
  LTS: 'Minor Moving Violation',
  NAF: 'Not At Fault Accident',
  NFX: 'Waived Not At Fault Accident',
  PUA: 'Permissive Use At Fault Accident',
  PUN: 'Permissive Use Not At Fault Accident',
  REF: 'Passing of a School Bus',
  SLV: 'Serious License Violation',
  SPD: 'Speeding',
  TMP: 'Dispute - At Fault Accident',
  UDR: 'Unverifiable Record',
  WSR: 'Wrong Way on a One Way Street'
};

export const homeClaimCodeToDescription = {
  D: 'Fire',
  E: 'Theft, Liability, Vandalism',
  F: 'All Other Claims (not Fire, Water, Theft, Liability or Vandalism)',
  G: 'Water'
};

// As part of data cost reduction in May 2023; we should remove once adding significant new business
export const disableUDIDLAndVRR = true;

export const fpcClassesImpactingPrice = ['7', '8', '8B', '9'];
export const strictFpcStateNotAllowedValues = ['7', '8', '8B', '9'];
export const strictFpcStates = ['AZ', 'CO', 'ID', 'MT', 'NM', 'OK', 'OR', 'TX', 'UT', 'WY'];
export const financialResponsibilityTiersImpactingPrice = ['L-1', 'M-1', 'N-1', 'O-1', 'P-1', 'Q-1', 'R-1'];
export const priorLapseDays = { TX: 32 };
export const policyType = {
  Home: 'H',
  Auto: 'A',
  ARBundle: 'AR',
  HABundle: 'HA',
  Renters: 'R',
  Condo: 'C',
  CABundle: 'CA',
  HomeBundle: 'HB',
  AutoBundle: 'AB'
};

export const policyTypelabels = { H: 'home', A: 'auto', R: 'rent', C: 'cond' };
export const planType = { Standard: 'S', Economy: 'E', Premium: 'P', Custom: 'C' };
export const paymentMethod = { CreditCard: 'C', ACH: 'E', Escrow: 'W' };
export const paymentType = { Monthly: 'I', OneTime: 'F', Escrow: 'E' };
export const chargeDeskPaymentMethodTypes = {
  Escrow: ['Check', 'Bank Transfer', 'Cash', 'Transfer'],
  Card: ['Debit', 'Prepaid', 'Credit'],
  ACH: ['Bank']
};

export const stripeBankStatus = {
  New: 'new',
  Validated: 'validated',
  Verified: 'verified',
  VerificationFailed: 'verification_failed',
  Errored: 'errored'
};

// status on stripe-specific charges/transactions
export const stripeChargeStatus = {
  Succeeded: 'succeeded',
  Pending: 'pending',
  Failed: 'failed'
};

// status on transactions when they are retrieved from billingHandler.getChargeDeskTransactions()
export const normalizedPaymentStatus = {
  Paid: 'paid',
  Pending: 'pending',
  Refunded: 'refunded',
  PartiallyRefunded: 'partially refunded',
  Failed: 'failed'
};

export const onboardingTasks = {
  StressFreeSwitchHome: 'StressFreeSwitchHome',
  StressFreeSwitchAuto: 'StressFreeSwitchAuto',
  StressFreeSwitchRenters: 'StressFreeSwitchRenters',
  StressFreeSwitchUmbrella: 'StressFreeSwitchUmbrella',
  CompleteInventory: 'Complete Inventory',
  ProvideProofOfGoodStudent: 'Provide Proof of Good Student',
  ProvideProofOfConnectedHome: 'Provide Proof of Connected Home',
  SignApplication: 'Sign Application'
};

export const monitoredStatusThatNeedProof = ['P', 'VP', 'C'];

export const taskDocumentType = {
  Policy: 'Policy',
  Internal: 'Internal'
};

export const cancelNoticeParagraphText = [
  {
    cancelNoticeName: 'CNRS',
    paragraphText:
      "We'll be happy to reinstate your policy if we receive the information requested above prior to cancellation. Just give us a call or, if you prefer, fax or mail the requested information along with a copy of this page to Branch. But don't delay. We won't be able to reinstate your policy if you contact us after the deadline."
  },
  {
    cancelNoticeName: 'CNPY',
    paragraphText:
      "Unfortunately, we didn't receive your payment and, as a result, your policy will be cancelled at 12:01 a.m. on [endDate]. Please know that this means you will no longer have insurance coverage. If you've already sent your payment, thank you. Your next regular payment will be due on [minimumAmountDueDate]. You can also pay online or over the phone using a credit card or authorizing a withdrawal from your bank account. We'll credit your payment right away so your insurance coverage will continue. We sincerely appreciate your attention to this matter and thank you for your business."
  }
];

export const acceptableCancelCodesForRewrite = ['CNRW'];

export const cancelCodeInformationalArray = [
  {
    cancelCode: 'CNDC',
    cancelReasonPlainText: 'Customer Dissatisfied with Claims',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNPR',
    cancelReasonPlainText: 'Customer Dissatisfied with Price',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNDR',
    cancelReasonPlainText: 'Customer Dissatisfied with Roadside',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Dissatisfied with Roadside'
  },
  {
    cancelCode: 'CNDB',
    cancelReasonPlainText: 'Customer Dissatisfied with Service',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNDA',
    cancelReasonPlainText: 'Customer Dissatisfied with Agency',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNMS',
    cancelReasonPlainText: 'Customer Misrepresentation',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Misrepresentation'
  },
  {
    cancelCode: 'CNPY',
    cancelReasonPlainText: 'Customer Non-Payment',
    cancelNoticeName: 'CNPY',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Non-Payment'
  },
  {
    cancelCode: 'CNID',
    cancelReasonPlainText: 'Documents Not Signed',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Documents Not Signed'
  },
  {
    cancelCode: 'CNCN',
    cancelReasonPlainText: 'Ineligible Condition of Home',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Ineligible Condition of Home',
    allowFreeFormNote: true
  },
  {
    cancelCode: 'CNMV',
    cancelReasonPlainText: 'Moving, Branch Eligible',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNMI',
    cancelReasonPlainText: 'Moving, Branch Ineligible',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNNO',
    cancelReasonPlainText: 'Other Customer Cancellation',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNUW',
    cancelReasonPlainText: 'Other Underwriting',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Underwriting; No Insurable Interest'
  },
  {
    cancelCode: 'CNSO',
    cancelReasonPlainText: "Sold the Insured Property/Didn't Purchase",
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNDP',
    cancelReasonPlainText: 'Duplicate Policy',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Duplicate Policy'
  },
  {
    cancelCode: 'CNIP',
    cancelReasonPlainText: 'Invalid Policy',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Invalid Policy'
  },
  {
    cancelCode: 'CNPP',
    cancelReasonPlainText: 'No Proof of Prior',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: No Proof of Prior Insurance'
  },
  {
    cancelCode: 'CNAP',
    cancelReasonPlainText: 'Accidental Purchase',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'CNBX',
    cancelReasonPlainText: 'Customer Convert to Bix',
    // no cancellation docs generated for CNBX
    cancelNoticeName: '',
    boxOneText: '',
    boxTwoText: ''
  },
  {
    cancelCode: 'CNNR',
    cancelReasonPlainText: 'Non-renewal',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-renewal: [fill in]',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'CNDE',
    cancelReasonPlainText: 'Customer Deceased',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  },
  {
    cancelCode: 'NA',
    cancelReasonPlainText: 'Unknown',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: [Fill In]'
  },
  {
    cancelCode: 'CNAG',
    cancelReasonPlainText: 'Agency Book Roll',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Previous Agency Policy'
  },
  {
    cancelCode: 'CNBN',
    cancelReasonPlainText: 'BIX Forced Non-Renewal',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText:
      'Reason For Nonrenewal: General Security National Insurance Company is no longer offering policies in your state. ',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'CNDY',
    cancelReasonPlainText: 'Bix Opt out',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Opt Out of BIX Policy '
  },
  {
    cancelCode: 'NRUW',
    cancelReasonPlainText: 'Non-Renewal For Underwriting Reasons',
    cancelNoticeName: 'NRRS',
    boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Non-Renewal: [Fill In]',
    nonRenew: true,
    allowFreeFormNote: true
  },
  {
    cancelCode: 'CNUV',
    cancelReasonPlainText: 'Underwriting: Ineligible Vehicle',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Underwriting; Ineligible Vehicle'
  },
  {
    cancelCode: 'CNUH',
    cancelReasonPlainText: 'Underwriting: Ineligible Driving History',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Underwriting; Ineligible Driving History'
  },
  {
    cancelCode: 'CNRW',
    cancelReasonPlainText: 'Policy Rewrite',
    cancelNoticeName: 'CNRS',
    boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
    boxTwoText: 'Reason(s) for Cancellation: Named Insured Request'
  }
];

export const passiveRestraintStates = ['PA'];
export const specificDriverAgeLogicStates = ['AL', 'LA', 'ME', 'SC', 'SD', 'VT'];
// If stormShutters checkbox is wanted to be shown in Advanced Windstorm section, add the state to 'stormShuttersInAdvancedWindstormSectionStates' array too.
export const stormShuttersStates = ['AL', 'MD', 'MS', 'TX', 'SC', 'VA'];
export const stormShuttersInAdvancedWindstormSectionStates = ['SC', 'LA'];
export const stormShuttersVerificationFormLinkStates = {
  SC: 'https://app.getguru.com/card/Txd87yMc/South-Carolina-Mitigation-Verification-Affidavit'
};
export const editableHouseholdMembersStates = ['IA', 'KS', 'NM', 'OR', 'SD'];
export const uimAddedOnStates = ['GA'];
export const earthquakeStates = ['KY', 'CA'];
export const palomarErrorPreventPurchasingStates = ['CA'];
export const floodStates = ['TN', 'CA'];
export const nonQuinstreetStates = ['MA', 'AK'];
export const editableResidenceInsuranceType = ['MA'];
export const residenceInsuranceTypes = {
  Home: 'O',
  Manufactured_Mobile: 'M',
  Condo: 'C',
  Renters: 'R',
  None_NotApplicable: 'T'
};

export const annualMilesDrivenLimits = {
  IA: {
    max: 30000,
    step: 1000
  },
  MA: {
    max: 30000,
    step: 1000
  },
  NE: {
    max: 30000,
    step: 1000
  },
  OR: {
    max: 30000,
    step: 1000
  },
  SD: {
    max: 30000,
    step: 1000
  }
};

export const excludeBIXMonolineUnderwritingStates = [];
export const hideHighestEducationStates = ['CA', 'FL', 'GA', 'ID', 'KS', 'MA', 'NY', 'VT'];
export const hideGenderStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];
export const mustRenewAutoLimit = {
  WV: {
    A: 4,
    H: 4
  }
};
export const equipmentBreakdownServiceLineCoveragesStates = [
  'AR',
  'AZ',
  'DC',
  'IA',
  'IL',
  'IN',
  'KY',
  'LA',
  'MD',
  'MI',
  'MO',
  'MS',
  'MT',
  'ND',
  'NE',
  'NM',
  'OH',
  'OK',
  'OR',
  'PA',
  'TX',
  'UT',
  'WI',
  'WV'
];

// dates EBSL should start in each state
export const EBSLstateDate = {
  AR: '01-20-2022',
  AZ: '01-20-2022',
  DC: '08-29-2022',
  GA: '06-01-2023',
  IA: '01-20-2022',
  IL: '08-30-2022',
  IN: '07-01-2022',
  KY: '08-20-2022',
  LA: '08-20-2022',
  MD: '07-21-2022',
  MI: '07-01-2022',
  MO: '08-13-2022',
  MS: '08-29-2022',
  MT: '01-20-2022',
  ND: '08-29-2022',
  NE: '07-15-2022',
  NM: '08-29-2022',
  OH: '01-20-2022',
  OK: '08-29-2022',
  OR: '08-01-2022',
  PA: '07-01-2022',
  TX: '07-26-2022',
  UT: '08-01-2022',
  WI: '07-01-2022',
  WV: '08-01-2022'
};

export const ibscDescriptionByState = {
  LA: 'We recommend 20% Incremental Coverage. This way, if it costs more to rebuild your home, you’ll have added peace of mind.',
  MS: 'We recommend 20% Incremental Coverage. This way, if it costs more to rebuild your home, you’ll have added peace of mind.',
  SD: 'We recommend 20% Incremental Coverage. This way, if it costs more to rebuild your home, you’ll have added peace of mind.'
};

export const driversLicenseRegexByState = {
  AB: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  AK: {
    regex: /^[0-9]{1,7}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 7 numbers'
  },
  AL: {
    regex: /^[0-9]{7,10}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 7 to 10 numbers'
  },
  AR: {
    regex: /^\d{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers'
  },
  AZ: {
    regex: /^(([a-z][0-9]{8})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 1 letter + 8 numbers or Social Security number'
  },
  BC: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  CA: {
    regex: /^[a-z]\d{7}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 7 numbers'
  },
  CO: {
    regex: /^\d{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 1 letter + up to 6 numbers'
  },
  CT: {
    regex: /^\d{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers'
  },
  DC: {
    regex: /^(([a-z]{2}\d{8})|(\d{7})|(\d{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 2 letters + 8 numbers, or 7 numbers, or Social Security number'
  },
  DE: {
    regex: /^[0-9]{1,7}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 7 numbers'
  },
  FL: {
    regex: /^[a-z]\d{11,12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 11-12 numbers'
  },
  GA: {
    regex: /^[0-9]{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers'
  },
  HI: {
    regex: /^((\d{9})|([H][0-9]{8}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of letter H + 8 numbers or Social Security number'
  },
  IA: {
    regex: /^((\d{9})|(\d{3}[a-z]{2}\d{4}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 9 numbers/letters (4th and 5th characters are letters)'
  },
  ID: {
    regex: /^(([a-z]{2}[0-9]{6}[a-z])|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 2 letters + 6 numbers + 1 letter or 9 numbers (issued prior to 5/1/93)'
  },
  IL: {
    regex: /^[a-z][0-9]{11}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 11 numbers'
  },
  IN: {
    regex: /^(([a-z]\d{9})|(\d{10}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 10 numbers or 1 letter + 9 numbers'
  },
  KS: {
    regex: /^((\d{9})|([a-z]\d{8})|([a-z0-9]{6}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 6 numbers/letters or 9 numbers or Social Security number or 1 leter + 9 numbers'
  },
  KY: {
    regex: /^(([a-z][0-9]{8})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 8 numbers or Social Security number'
  },
  LA: {
    regex: /^[0-9]{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers. If less than 9 numbers, fill in with zeros on the front end'
  },
  MA: {
    regex: /^(([a-z]{2}[0-9]{7})|([a-z][0-9]{8})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 1 letter/number + 8 numbers'
  },
  MB: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  MD: {
    regex: /^(([a-z][0-9]{12})|(md\d{11}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 12 numbers, or "MD" followed by 11 digits'
  },
  ME: {
    regex: /^(\d{7})$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 7 numbers'
  },
  MI: {
    regex: /^[a-z]\d{12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 12 numbers'
  },
  MN: {
    regex: /^[a-z]\d{12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 12 numbers'
  },
  MO: {
    // eslint-disable-next-line prefer-regex-literals
    regex: new RegExp(
      '^(([a-z][a-z0-9]{5,9})|([0-9]{9,10})|([0-9]{9}[a-z])|([a-z][0-9]{15,16})|([a-z][0-9]{15}[a-z])|([0-9]{3}[a-z][0-9]{6}))$',
      'i'
    ),
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage:
      'Must consist of 9 to 10 numbers or 9 numbers + 1 letter or 1 letter + 15 to 16 numbers or 1 letter + 15 numbers + 1 letter or 1 letter + 5 to 9 letters/numbers or 3 numbers + 1 letter + 6 numbers'
  },
  MS: {
    regex: /^[0-9]{9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers'
  },
  MT: {
    regex: /^(([a-z0-9]{9})|([a-z0-9]{13}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 13 characters or 9 numbers or 9 letters/numbers'
  },
  NB: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  NC: {
    regex: /^[0-9]{1,12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 12 numbers'
  },
  ND: {
    regex: /^(([a-z]{3}[0-9]{6})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 3 letters + 6 numbers or Social Security number'
  },
  NE: {
    regex: /^[a-z][0-9]{3,8}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 3-8 numbers'
  },
  NF: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  NH: {
    regex: /^[a-z0-9]{10,11}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 10-11 letters/numbers'
  },
  NJ: {
    regex: /^[a-z][0-9]{14}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 14 numbers '
  },
  NM: {
    regex: /^[0-9]{8,9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 to 9 numbers '
  },
  NS: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  NT: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  NV: {
    regex: /^(([0-9]{9,10})|([0-9]{12})|(X[0-9]{8})|([0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 to 10 numbers or 12 numbers or X + 8 numbers or Social Security number '
  },
  NY: {
    regex: /^(([0-9]{9})|([a-z][0-9]{16,20}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 9 numbers or 1 letter + 16 to 20 numbers or 16 - 20 numbers'
  },
  OH: {
    regex: /^([a-z]{2}[0-9]{6})$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 2 letters + 6 numbers'
  },
  OK: {
    regex: /^([a-z]{1}[0-9]{9})$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 9 numbers'
  },
  ON: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  OR: {
    regex: /^(([0-9]{1,7})|([a-z][0-9]{6})|([a-z]{2}[0-9]{5}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 7 numbers or 1 letter + 6 numbers or 2 letters + 5 numbers'
  },
  PA: {
    regex: /^[0-9]{8}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 numbers'
  },
  PE: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  PQ: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  RI: {
    regex: /^(([V][0-9]{6})|([0-9]{7}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 7 numbers or V + 6 numbers'
  },
  SC: {
    regex: /^[0-9]{1,10}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 to 10 numbers'
  },
  SD: {
    regex: /^(([0-9]{6})|([0-9]{8,9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 6 or 8 to 9 numbers'
  },
  SK: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  },
  TN: {
    regex: /^[0-9]{8,9}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 to 9 numbers'
  },
  TX: {
    regex: /^([0-9]{8}[a-zA-Z]|[0-9]{8})$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 numbers'
  },
  UT: {
    regex: /^(([0-9]{4,9})|(0[0-9]{9}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 4 to 9 numbers or 10 numbers (first position must be 0)'
  },
  VA: {
    regex: /^(([a-z][0-9]{8})|([0-9]{9})|([0-9]{12}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 12 numbers or 1 letter + 8 numbers or Social Security number'
  },
  VT: {
    regex: /^[0-9]{7}[A0-9]$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 8 numbers or 7 numbers + A'
  },
  WA: {
    regex: /^[a-z0-9*]{12}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 12 letters/numbers'
  },
  WI: {
    regex: /^[a-z][0-9]{13}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: 'Must consist of 1 letter + 13 numbers'
  },
  WV: {
    regex: /^(([ABCDEFISX][0-9]{6})|([X1][X][0-9]{5})|([a-z][0-9]{6})|([0-9]{7}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage:
      'Must consist of 1 letter + 6 numbers or 2 letters + 5 numbers or 1 letter + 1 number + 5 numbers or 7 numbers'
  },
  WY: {
    regex: /^(([0-9]{9})|([0-9]{6}[-][0-9]{3}))$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: "Must consist of 9 numbers or 6 numbers then a '-' then 3 more numbers"
  },
  YT: {
    regex: /^.{2,16}$/i,
    commonErrorMessage: 'Driver License Number is not valid.',
    errorMessage: ''
  }
};

export const allowedDirectMonolineCurrentCarriers = [
  '00109', // Everspan
  '00128',
  '00182',
  '00270',
  '00273',
  '00277',
  '00349',
  '00366',
  '00391',
  '00460',
  '00466',
  '00542',
  '00548',
  '00586',
  '00588',
  '00601',
  '00603',
  '00609',
  '00638',
  '00643',
  '00649',
  '00662',
  '00671',
  '00712',
  '00762',
  '00764',
  '00934',
  '00956',
  '01737',
  '01743',
  '01744',
  '01762',
  '01767',
  '01812',
  '01839',
  '01852',
  '01853',
  '01864',
  '01873',
  '01891',
  '01896',
  '01900',
  '01921',
  '01978',
  '01987',
  '01996',
  '02001',
  '02002',
  '02016',
  '02017',
  '02018',
  '02022',
  '02023',
  '02028',
  '02084',
  '02085',
  '02086',
  '02088',
  '02147',
  '02159',
  '02171',
  '02172',
  '02173',
  '02174',
  '02204',
  '02205',
  '02229',
  '02230',
  '02231',
  '02232',
  '02234',
  '02235',
  '02253',
  '02254',
  '02276',
  '02282',
  '02283',
  '02286',
  '02287',
  '02289',
  '02290',
  '02356',
  '02357',
  '02358',
  '02360',
  '02361',
  '02363',
  '02378',
  '02379',
  '02385',
  '02386',
  '02389',
  '02393',
  '02394',
  '02407',
  '02408',
  '02446',
  '02447',
  '02448',
  '02452',
  '02453',
  '02476',
  '02477',
  '02478',
  '02479',
  '02483',
  '02496',
  '02513',
  '02516',
  '02517',
  '02518',
  '02520',
  '02523',
  '02526',
  '02538',
  '02550',
  '02577',
  '02594',
  '02609',
  '02610',
  '02611',
  '02612',
  '02613',
  '02614',
  '02634',
  '02640',
  '02650',
  '02665', // GSNIC
  '02701',
  '02709',
  '02761',
  '02784',
  '02855',
  '02866',
  '03028',
  '03045',
  '03100',
  '03132',
  '03288',
  '03289',
  '03292',
  '03299',
  '03312',
  '03539',
  '03566',
  '03596',
  '03645',
  '03652',
  '03690',
  '03733',
  '03756',
  '03779',
  '03791',
  '03792',
  '03794',
  '03795',
  '03831',
  '04003',
  '04025',
  '04046',
  '04049',
  '04083',
  '04097',
  '04158',
  '04159',
  '04240',
  '04274',
  '04287',
  '04305',
  '04306',
  '04311',
  '04456',
  '04461',
  '04465',
  '04675',
  '04785',
  '04865',
  '04869',
  '04938',
  '04976',
  '06627',
  '10106',
  '10130',
  '10346',
  '10419',
  '10648',
  '10678',
  '10709',
  '10724',
  '10745',
  '10764',
  '10765',
  '10777',
  '10852',
  '11020',
  '11024',
  '11025',
  '11026',
  '11027',
  '11055',
  '11087',
  '11088',
  '11150',
  '11224',
  '11241',
  '11244',
  '11246',
  '11247',
  '11248',
  '11300',
  '11302',
  '11325',
  '11354',
  '11417',
  '11421',
  '11441',
  '11578',
  '11654',
  '11665',
  '11688',
  '11689',
  '11698',
  '11699',
  '11702',
  '11703',
  '11758',
  '11759',
  '11760',
  '11761',
  '11762',
  '11778',
  '11779',
  '11780',
  '11789',
  '11790',
  '11794',
  '11802',
  '11872',
  '11946',
  '11979',
  '12051',
  '12053',
  '12058',
  '12059',
  '12078',
  '12089',
  '12106',
  '12120',
  '12121',
  '12128',
  '12150',
  '12163',
  '12212',
  '12213',
  '12238',
  '12410',
  '12421',
  '12425',
  '12460',
  '12535',
  '12536',
  '12560',
  '12561',
  '12563',
  '12569',
  '12612',
  '12710',
  '12711',
  '13016',
  '13080',
  '13082',
  '13083',
  '13107',
  '13866',
  '13878',
  '13917',
  '14235',
  '14301',
  '14302',
  '14303',
  '18538',
  '22001',
  '22059',
  '22601',
  '23178',
  '66716', // BIX
  '86485'
];

export const newBusinessMoratoriumZipCodes = [
  // TX DFW Zips blocked from 12/1/22
  '75001',
  '75002',
  '75006',
  '75007',
  '75009',
  '75010',
  '75013',
  '75019',
  '75022',
  '75023',
  '75024',
  '75025',
  '75028',
  '75032',
  '75033',
  '75034',
  '75035',
  '75036',
  '75038',
  '75039',
  '75040',
  '75041',
  '75042',
  '75043',
  '75044',
  '75048',
  '75050',
  '75051',
  '75052',
  '75054',
  '75056',
  '75057',
  '75060',
  '75061',
  '75062',
  '75063',
  '75065',
  '75067',
  '75068',
  '75069',
  '75070',
  '75071',
  '75072',
  '75074',
  '75075',
  '75077',
  '75078',
  '75080',
  '75081',
  '75082',
  '75086',
  '75087',
  '75088',
  '75089',
  '75093',
  '75094',
  '75097',
  '75098',
  '75101',
  '75104',
  '75105',
  '75114',
  '75115',
  '75116',
  '75119',
  '75125',
  '75126',
  '75132',
  '75134',
  '75135',
  '75137',
  '75141',
  '75142',
  '75143',
  '75146',
  '75147',
  '75149',
  '75150',
  '75152',
  '75154',
  '75155',
  '75157',
  '75158',
  '75159',
  '75160',
  '75161',
  '75164',
  '75165',
  '75166',
  '75167',
  '75169',
  '75172',
  '75173',
  '75180',
  '75181',
  '75182',
  '75189',
  '75201',
  '75202',
  '75203',
  '75204',
  '75205',
  '75206',
  '75207',
  '75208',
  '75209',
  '75210',
  '75211',
  '75212',
  '75214',
  '75215',
  '75216',
  '75217',
  '75218',
  '75219',
  '75220',
  '75223',
  '75224',
  '75225',
  '75226',
  '75227',
  '75228',
  '75229',
  '75230',
  '75231',
  '75232',
  '75233',
  '75234',
  '75235',
  '75236',
  '75237',
  '75238',
  '75240',
  '75241',
  '75243',
  '75244',
  '75246',
  '75247',
  '75248',
  '75249',
  '75251',
  '75252',
  '75253',
  '75254',
  '75287',
  '75402',
  '75407',
  '75409',
  '75424',
  '75442',
  '75454',
  '75474',
  '76001',
  '76002',
  '76006',
  '76008',
  '76009',
  '76010',
  '76011',
  '76012',
  '76013',
  '76014',
  '76015',
  '76016',
  '76017',
  '76018',
  '76020',
  '76021',
  '76022',
  '76028',
  '76031',
  '76033',
  '76034',
  '76036',
  '76039',
  '76040',
  '76041',
  '76044',
  '76050',
  '76051',
  '76052',
  '76053',
  '76054',
  '76058',
  '76059',
  '76060',
  '76061',
  '76063',
  '76064',
  '76065',
  '76071',
  '76078',
  '76084',
  '76092',
  '76102',
  '76103',
  '76104',
  '76105',
  '76106',
  '76107',
  '76108',
  '76109',
  '76110',
  '76111',
  '76112',
  '76114',
  '76115',
  '76116',
  '76117',
  '76118',
  '76119',
  '76120',
  '76123',
  '76126',
  '76127',
  '76129',
  '76131',
  '76132',
  '76133',
  '76134',
  '76135',
  '76137',
  '76140',
  '76148',
  '76155',
  '76164',
  '76177',
  '76179',
  '76180',
  '76182',
  '76201',
  '76205',
  '76206',
  '76207',
  '76208',
  '76209',
  '76210',
  '76226',
  '76227',
  '76244',
  '76247',
  '76248',
  '76249',
  '76258',
  '76259',
  '76262',
  '76266',
  '76651'
];

export const nodePathToFriendlyName = {
  'homeCoverage.coverageA': 'Replacement Cost',
  'homeCoverage.coverageIBSC': 'Incremental Building Structures Coverage Limit',
  'homeCoverage.coverageBCPctOfA': 'Building Codes Coverage Limit',
  'homeCoverage.coverageBPctOfA': 'Other Structures Coverage Limit',
  'homeCoverage.coverageCPctOfA': 'Personal Property Coverage Limit',
  'homeCoverage.coverageDPctOfA': 'Additional Living Expenses Coverage Limit',
  'homeCoverage.coverageX': 'Familiy Liability Coverage Limit',
  'homeCoverage.coverageY': 'Guest Medical Coverage Limit',
  'homeCoverage.coverageBP': 'Additional Coverage on Business Property Limit',
  'homeCoverage.coverageCA': 'Additional Coverage on Camera Property Limit',
  'homeCoverage.coverageDR': 'Electronic Data Recovery Coverage',
  'homeCoverage.coverageF': 'Limit on Fire Department Charges',
  'homeCoverage.coverageG': 'Loss Assessments Limit',
  'homeCoverage.coverageJ': 'Additional Coverage on Jewelry, Watches, and Furs Limit',
  'homeCoverage.coverageMI': 'Additional Coverage on Musical Instruments Limit',
  'homeCoverage.coverageMS': 'Mine Subsidence Coverage',
  'homeCoverage.coverageMSALE': 'Mine Subsidence ALE Coverage',
  'homeCoverage.coverageSP': 'Additional Coverage on Sports Equipment Limit',
  'homeCoverage.coverageST': 'Additional Coverage on Silverware Theft Limit'
};

export const coloradoRulesForViolationsStatesAndDates = {
  CO: 20220519
};

// Jordan Rules are: use 59 months for the two five-year discounts, and otherwise
// only rate on the 35-month period
export const jordanRulesForViolationsStatesAndDates = {
  AK: 20220718,
  AL: 20220718,
  AR: 20220718,
  AZ: 20220718,
  CA: 20220718,
  CT: 20220718,
  DC: 20220718,
  DE: 20220718,
  FL: 20220718,
  GA: 20220718,
  HI: 20220718,
  IA: 20220718,
  ID: 20220718,
  IL: 20220718,
  IN: 20220718,
  KS: 20220718,
  KY: 20220718,
  LA: 20220718,
  MA: 20220718,
  MD: 20220718,
  ME: 20220718,
  MI: 20220718,
  MN: 20220718,
  MO: 20220718,
  MS: 20220718,
  MT: 20220718,
  NC: 20220718,
  ND: 20220718,
  NE: 20220718,
  NH: 20220718,
  NJ: 20220718,
  NM: 20220718,
  NV: 20220718,
  NY: 20220718,
  OH: 20220718,
  OK: 20220718,
  OR: 20220718,
  PA: 20220718,
  RI: 20220718,
  SC: 20220718,
  SD: 20220718,
  TN: 20220718,
  TX: 20220718,
  UT: 20220718,
  VA: 20220718,
  VT: 20220718,
  WA: 20220718,
  WI: 20220718,
  WV: 20220718,
  WY: 20220718
};

export const seniorAge = {
  MA: 65
};

export const inexperiencedYearsLimit = {
  MA: 7
};

export const resellerRatingsAgenciesWhiteList = ['VIV1', 'VV1', 'VV2', 'VV3', 'VV4', 'VVE', 'BE0001'];

export const homeInspectionVendors = {
  IRS: 'irs',
  MUELLER: 'mueller'
};

// This configuration blocks monoline policies for the given states and policy types
// This check is executed at the underwriting rule level after the data pulls are complete and
// before creating the options
export const blockMonolinePolicies = {
  CO: {
    GH1: ['H', 'A', 'C'],
    GH1P: ['H', 'A', 'C'],
    GH2: ['H', 'A', 'C'],
    GH3: ['H', 'A', 'C'],
    GHD: ['H', 'A', 'C'],
    GU0001: ['H', 'A', 'C'],
    GU0001_1: ['H', 'A', 'C'],
    MATIC1: ['H', 'A', 'C'],
    MATIC1D: ['H', 'A', 'C']
  },
  GA: {
    GH1: ['H', 'A', 'C'],
    GH1P: ['H', 'A', 'C'],
    GH2: ['H', 'A', 'C'],
    GH3: ['H', 'A', 'C'],
    GHD: ['H', 'A', 'C'],
    GU0001: ['H', 'A', 'C'],
    GU0001_1: ['H', 'A', 'C'],
    MATIC1: ['H', 'A', 'C'],
    MATIC1D: ['H', 'A', 'C']
  },
  OK: {
    GH1: ['H', 'A', 'C'],
    GH1P: ['H', 'A', 'C'],
    GH2: ['H', 'A', 'C'],
    GH3: ['H', 'A', 'C'],
    GHD: ['H', 'A', 'C'],
    MATIC1: ['H', 'A', 'C'],
    MATIC1D: ['H', 'A', 'C']
  }
};

type BlockMonolinePoliciesByStateAndAffinityRegex = {
  [state: string]: Array<{ regex: RegExp; policyTypes: string[] }> | undefined;
};

export const blockMonolinePoliciesByStateAndAffinityRegex: BlockMonolinePoliciesByStateAndAffinityRegex = {
  GA: [{ regex: /^GU0001.*$/, policyTypes: ['H', 'A', 'C'] }],
  // Country wide monoline blocks
  '*': [
    // { regex: /^WAY0001.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^ZB1.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^GU0001.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^JE0003_.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^TRELC.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^IN1.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^IN0053.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^IN0048.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^EL0004.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^FREF1.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^TAS_.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^TAS$/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^TAS2$/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^DI0009_1$/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^DI0009P$/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^BU0004_.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^ST0007.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^MY0003.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^IN0015.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^RGD1.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^RO0013.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^RC0004.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^SALTY.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^DI0009.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^HO0004.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^WO0004.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^AU0002.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^CV0002.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^UN0003.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^DH0001.?.*/, policyTypes: ['H', 'A', 'C'] },
    // { regex: /^YA1_.?.*/, policyTypes: ['H', 'A', 'C'] }
  ]
};

// This configuration is to completely block this affinities to quote in the states that are specified
// This check is executed at the rater before doing any of the data pulls
export const blockStatesForAffinity = {
  MD: [
    'TRELC',
    'TRELCD',
    'TRELCR',
    'TRELCRD',
    'TRELC_1',
    'TRELC_2',
    'TRELC_3',
    'TRELC_4',
    'TRELC_5',
    'TRELC_6',
    'TRELC_7',
    'AMEX1'
  ],
  AZ: ['AMEX1'],
  MI: ['AMEX1'],
  // Country wide blocks
  '*': [/^WAY0001.*/, /^RGD1.*/, /^QWZE.*/, /^QWZS.*/, /^JE0003.*/, 'SALTY', 'SALTYP', /^ZB1.*/]
};

export const homeInspectionVendorByState = {
  AZ: homeInspectionVendors.MUELLER,
  CO: homeInspectionVendors.MUELLER,
  IA: homeInspectionVendors.MUELLER,
  ID: homeInspectionVendors.MUELLER,
  KS: homeInspectionVendors.MUELLER,
  MT: homeInspectionVendors.MUELLER,
  ND: homeInspectionVendors.MUELLER,
  NE: homeInspectionVendors.MUELLER,
  NM: homeInspectionVendors.MUELLER,
  OK: homeInspectionVendors.MUELLER,
  OR: homeInspectionVendors.MUELLER,
  SD: homeInspectionVendors.MUELLER,
  UT: homeInspectionVendors.MUELLER,
  WY: homeInspectionVendors.MUELLER
};

export const InspectionStatus = {
  NotOrdered: 'Not ordered',
  Ordered: 'Ordered'
};

export const HomeInspectionTypes = {
  IRS: {
    ExteriorObservation: 'EO1',
    ExteriorObservationPlus4Point: 'EO4',
    ExteriorObservationPlus4PointRoof: 'EO4_ROOFTOP',
    ExteriorObservationRoof: 'EO_Rooftop' // idk why this one is not the same casing as the rest of them
  },
  MUELLER: {
    ExteriorObservation: 'EXTERIOR (NO DIAGRAM OR R/C)',
    ExteriorObservationWithSupplementForm: 'DRIVEBY_SUPP',
    Wildfire: 'BS' // Brush Supplement
  }
};

export const deletedDriversReasons = {
  PRIOR_OWNER: 'PRIOR_OWNER',
  NON_RELATIVE: 'NON_RELATIVE',
  NON_LICENSED: 'NON_LICENSED',
  MILITARY_AWAY: 'MILITARY_AWAY',
  RESIDENT_RELATIVE_OWN_INS: 'RESIDENT_RELATIVE_OWN_INS',
  DECEASED: 'DECEASED'
};
